import axios from 'axios';
import { env } from './function';
import { getCookie } from './cookies';

const server = axios.create({
    baseURL: env('SERVER'),
    withCredentials: false,
});

server.interceptors.request.use(function (config) {
    const accessToken = getCookie('accessToken');
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
});

export { server };
