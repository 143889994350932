import { MenuItem, Select, TextField, Stack, Divider } from '@mui/material';
import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Submit, useForm } from '../../../hooks/useForm';
import { Input } from '../../../hooks/useForm/inputs/index';
import { useMessage } from '../../../components/Header';
import Loading from '../../../components/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import { Tab, Tabs } from '../../../styles/tabs';
import Add from '@mui/icons-material/Add';
import useLoader from '../../../hooks/useLoader';
import useErrorHandler from '../../../hooks/useErrorHandler';
import { server } from '../../../utils/axios';
import { useUser } from '../../../hooks/Authorize';

function TabPanel(props) {
    const { children, value, index } = props;

    return (
        <div role='tabpanel' hidden={value !== index} style={{ height: '90%' }}>
            {value === index && children}
        </div>
    );
}

const extraFieldsTab = new Set();
const newTestFormat = {
    type: '',
    quotient: '',
    years: '',
    months: '',
};

const NewAssessment = () => {
    const { clientId } = useParams();
    const errorHandler = useErrorHandler();
    const [client, setClient] = useState({
        name: '',
        dob: '',
        gender: '',
    });
    const { start, end, loaderState, circular } = useLoader();
    const [department, setDepartment] = useState({ assessment_fields: [] });
    const user = useUser();

    const getClient = useCallback(async () => {
        try {
            const response = await server.get(`/clients/${clientId}`, {
                params: { expand: 'departments' },
            });
            const { name, gender, dob } = response.data;

            setClient({
                name,
                gender,
                dob,
            });
        } catch (e) {
            errorHandler(e);
        }
    }, [clientId, setClient, errorHandler]);

    const getDepartments = useCallback(async () => {
        start();
        try {
            const response = await server.get(`/departments/`);
            const departments = response.data.results;

            setDepartment(
                departments.find(
                    department => department.name === user.department_name
                ) || {}
            );
        } catch (e) {
            errorHandler(e);
        } finally {
            end();
        }
    }, [errorHandler, start, end, user.department_name]);

    const departmentFields = useMemo(() => {
        const obj = {};
        department.assessment_fields?.forEach(field => {
            if (field.required) extraFieldsTab.add('$' + field.name);
            obj['$' + field.name] = { required: field.required };
        });

        console.log({ department, obj });

        return obj;
    }, [department]);

    useEffect(() => {
        if (clientId) getClient();
    }, [clientId, getClient]);

    useEffect(() => {
        if (user.department) getDepartments();
    }, [getDepartments, user.department]);

    return loaderState ? (
        circular
    ) : (
        <AssessmentForm
            client={client}
            department={department}
            departmentFields={departmentFields}
        />
    );
};

const historyTab = [
    'parental_history',
    'department_history',
    'family_history',
    'school_history',
];

const AssessmentForm = props => {
    const { clientId, draftId } = useParams();
    const { client, department, departmentFields } = props;
    const { showSuccess } = useMessage();
    const [tabSelected, setTabSelected] = useState(0);
    const [tests, setTests] = useState([{ ...newTestFormat }]);
    const { start, end, loaderState } = useLoader();
    const errorHandler = useErrorHandler();
    const handleChange = (event, newValue) => {
        setTabSelected(newValue);
    };

    const navigate = useNavigate();

    const initialValues = useMemo(
        () => ({
            date_of_assessment: {
                required: true,
                value: new Date().toISOString().slice(0, 10),
            },
            test_results: { required: true },
            presenting_complaints: '',
            behavioural_observation: '',
            impression: { required: true },
            recommendations: { required: true },
            parental_history: { required: true },
            family_history: { required: true },
            development_history: { required: true },
            school_history: { required: true },
            ...departmentFields,
        }),
        [departmentFields]
    );

    const handlers = useForm(initialValues, { Input: TextField });

    const setValues = handlers.setValues;
    const errors = handlers.errors;
    const values = handlers.values;

    const getDraftAssessment = useCallback(
        async clientId => {
            start();
            try {
                const response = await server.get(`/draft/`, {
                    params: { client_id: clientId },
                });

                const {
                    behavioural_observation,
                    date_of_assessment,
                    development_history,
                    family_history,
                    impression,
                    parental_history,
                    presenting_complaints,
                    recommendations,
                    school_history,
                    test_results,
                } = response.data.data;

                setValues({
                    behavioural_observation,
                    date_of_assessment,
                    development_history,
                    family_history,
                    impression,
                    parental_history,
                    presenting_complaints,
                    recommendations,
                    school_history,
                    test_results,
                });
            } catch (e) {
                errorHandler(e);
            } finally {
                end();
            }
        },
        [start, end, setValues, errorHandler]
    );

    const savedAsDraft = async () => {
        try {
            await server.post(
                `/assessments/`,
                { data: values, draft: true },
                { params: { client_id: clientId } }
            );
            navigate(-1);
        } catch (e) {
            errorHandler(e);
        }
    };

    const addMoreTest = () => {
        setTests([...tests, { ...newTestFormat }]);
    };

    const handleTestChange = (e, i) => {
        const newValue = e.target.value;
        const name = e.target.name;
        tests[i][name] = newValue;
        setTests([...tests]);
    };

    const onSubmit = response => {
        if (response.data) {
            navigate(-1);
            showSuccess('Assessment saved successfully!');
        }
    };

    useEffect(() => {
        if (draftId && clientId) getDraftAssessment(clientId);
    }, [draftId, clientId, getDraftAssessment]);

    console.log({ extraFieldsTab, errors });

    useEffect(() => {
        const historyError = historyTab.some(field => errors[field]);
        if (historyError) setTabSelected(0);

        const extraFieldError = Array.from(extraFieldsTab).some(
            field => errors[field]
        );
        if (extraFieldError) setTabSelected(2);
    }, [errors]);

    return (
        <Box
            bgcolor='background.paper'
            height='calc(100vh - 76px)'
            overflow='auto'
            display='flex'
            flexDirection='column'
            p={0}>
            <Typography variant='h5' fontWeight={600} color='primary' p={2}>
                'New Assessment'
            </Typography>
            <Divider variant='fullWidth' />
            {loaderState ? (
                <Loading message='Please wait, while your credentials are loading...' />
            ) : (
                <Form
                    handlers={handlers}
                    onSubmit={onSubmit}
                    action={`/assessments/?client_id=${clientId}`}
                    method={'post'}
                    onError={errorHandler}
                    axiosInstance={server}
                    final={values => {
                        const extra_fields = {};

                        Object.keys(values).forEach(key => {
                            if (key.startsWith('$')) {
                                const field = key.slice(1);
                                extra_fields[field] = values[key];
                            }
                        });
                        return { ...values, extra_fields, tests };
                    }}
                    style={{ flexGrow: 1, position: 'relative' }}>
                    <Box
                        p={2}
                        display='flex'
                        flexDirection='column'
                        mb={4}
                        overflow='auto'>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Client Name
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                lg={10}
                                display='flex'
                                spacing={2}>
                                <TextField
                                    variant='outlined'
                                    size='small'
                                    value={client.name}
                                    disabled={true}
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '90%',
                                            lg: '316px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Date of Birth
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                lg={10}
                                display='flex'
                                spacing={2}>
                                <TextField
                                    variant='outlined'
                                    size='small'
                                    type='date'
                                    value={client.dob}
                                    disabled={true}
                                    sx={{ width: 150, mr: 2 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Gender
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                lg={10}
                                display='flex'
                                spacing={2}>
                                <Select
                                    size='small'
                                    name='gender'
                                    sx={{ width: 316 }}
                                    disabled={true}
                                    value={client.gender}>
                                    <MenuItem value='male'>Male</MenuItem>
                                    <MenuItem value='female'>Female</MenuItem>
                                    <MenuItem value='other'>Other</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Date of Assessment
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10}>
                                <Input
                                    name='date_of_assessment'
                                    variant='outlined'
                                    size='small'
                                    type='date'
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '90%',
                                            lg: '316px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Test Results
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10}>
                                <Input
                                    name='test_results'
                                    variant='outlined'
                                    size='small'
                                    multiline
                                    rows={4}
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '90%',
                                            lg: '316px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Behavioural Observation
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10}>
                                <Input
                                    name='behavioural_observation'
                                    variant='outlined'
                                    size='small'
                                    multiline
                                    rows={4}
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '90%',
                                            lg: '316px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Presenting Complaints
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10}>
                                <Input
                                    name='presenting_complaints'
                                    variant='outlined'
                                    size='small'
                                    multiline
                                    rows={4}
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '90%',
                                            lg: '316px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Impression
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10}>
                                <Input
                                    name='impression'
                                    variant='outlined'
                                    multiline
                                    rows={4}
                                    size='small'
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '90%',
                                            lg: '316px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Recommendations
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10}>
                                <Input
                                    name='recommendations'
                                    variant='outlined'
                                    multiline
                                    rows={4}
                                    size='small'
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '90%',
                                            lg: '316px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Tabs
                            value={tabSelected}
                            onChange={handleChange}
                            sx={{ mt: 4 }}>
                            <Tab label='History' />
                            <Tab label='Tests' />
                            {department.assessment_fields.length && (
                                <Tab label='Extra fields' />
                            )}
                        </Tabs>
                        <Box flexGrow={1} py={2} mb={3}>
                            <TabPanel value={tabSelected} index={0}>
                                <Grid
                                    container
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems='center'
                                    mb={2}>
                                    <Grid item xs={12} lg>
                                        <Typography
                                            variant='body2'
                                            fontWeight={600}>
                                            Parental History
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} lg={10}>
                                        <Input
                                            name='parental_history'
                                            variant='outlined'
                                            size='small'
                                            multiline
                                            rows={4}
                                            sx={{
                                                width: {
                                                    xs: '100%',
                                                    sm: '90%',
                                                    lg: '316px',
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems='center'
                                    mb={2}>
                                    <Grid item xs={12} lg>
                                        <Typography
                                            variant='body2'
                                            fontWeight={600}>
                                            Family History
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        lg={10}
                                        display='flex'
                                        spacing={2}>
                                        <Input
                                            name='family_history'
                                            variant='outlined'
                                            size='small'
                                            multiline
                                            rows={4}
                                            sx={{
                                                width: {
                                                    xs: '100%',
                                                    sm: '90%',
                                                    lg: '316px',
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems='center'
                                    mb={2}>
                                    <Grid item xs={12} lg>
                                        <Typography
                                            variant='body2'
                                            fontWeight={600}>
                                            Development History
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        lg={10}
                                        display='flex'
                                        spacing={2}>
                                        <Input
                                            name='development_history'
                                            variant='outlined'
                                            size='small'
                                            multiline
                                            rows={4}
                                            sx={{
                                                width: {
                                                    xs: '100%',
                                                    sm: '90%',
                                                    lg: '316px',
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems='center'
                                    mb={2}>
                                    <Grid item xs={12} lg>
                                        <Typography
                                            variant='body2'
                                            fontWeight={600}>
                                            School History
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        lg={10}
                                        display='flex'
                                        spacing={2}>
                                        <Input
                                            name='school_history'
                                            variant='outlined'
                                            size='small'
                                            multiline
                                            rows={4}
                                            sx={{
                                                width: {
                                                    xs: '100%',
                                                    sm: '90%',
                                                    lg: '316px',
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={tabSelected} index={1}>
                                <Grid
                                    container
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems='center'
                                    mb={2}>
                                    {tests.map((test, i) => (
                                        <React.Fragment key={i}>
                                            <Grid item xs={12} md={6}>
                                                <Grid
                                                    container
                                                    alignItems='center'
                                                    spacing={1}
                                                    mb={2}>
                                                    <Grid item xs={12} sm lg>
                                                        <Typography
                                                            variant='body2'
                                                            fontWeight={600}>
                                                            Tests Administered
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm
                                                        lg={8}>
                                                        <Select
                                                            size='small'
                                                            name='type'
                                                            value={test.type}
                                                            onChange={e =>
                                                                handleTestChange(
                                                                    e,
                                                                    i
                                                                )
                                                            }
                                                            sx={{
                                                                width: {
                                                                    xs: '100%',
                                                                    sm: '90%',
                                                                    lg: '316px',
                                                                },
                                                            }}>
                                                            <MenuItem value='DST'>
                                                                DST
                                                            </MenuItem>
                                                            <MenuItem value='VSMS'>
                                                                VSMS
                                                            </MenuItem>
                                                            <MenuItem value='MISIC'>
                                                                MISIC
                                                            </MenuItem>
                                                            <MenuItem value='BKT'>
                                                                BKT
                                                            </MenuItem>
                                                        </Select>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    alignItems='center'
                                                    spacing={1}
                                                    mb={2}>
                                                    <Grid item xs={12} sm lg>
                                                        <Typography
                                                            variant='body2'
                                                            fontWeight={600}>
                                                            Score
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm
                                                        lg={8}>
                                                        <TextField
                                                            variant='outlined'
                                                            size='small'
                                                            name='quotient'
                                                            type='number'
                                                            value={
                                                                test.quotient
                                                            }
                                                            onChange={e =>
                                                                handleTestChange(
                                                                    e,
                                                                    i
                                                                )
                                                            }
                                                            sx={{
                                                                width: {
                                                                    xs: '100%',
                                                                    sm: '90%',
                                                                    lg: '316px',
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Grid
                                                    container
                                                    alignItems='center'
                                                    spacing={1}
                                                    mb={2}>
                                                    <Grid item xs={12} sm lg>
                                                        <Typography
                                                            variant='body2'
                                                            fontWeight={600}>
                                                            Dev.Years
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm
                                                        lg={8}>
                                                        <TextField
                                                            variant='outlined'
                                                            size='small'
                                                            name='years'
                                                            type='number'
                                                            value={test.years}
                                                            onChange={e =>
                                                                handleTestChange(
                                                                    e,
                                                                    i
                                                                )
                                                            }
                                                            sx={{
                                                                width: {
                                                                    xs: '100%',
                                                                    sm: '90%',
                                                                    lg: '316px',
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    alignItems='center'
                                                    spacing={1}
                                                    mb={2}>
                                                    <Grid item xs={12} sm lg>
                                                        <Typography
                                                            variant='body2'
                                                            fontWeight={600}>
                                                            Dev.Months
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm
                                                        lg={8}>
                                                        <TextField
                                                            name='months'
                                                            variant='outlined'
                                                            size='small'
                                                            type='number'
                                                            value={test.months}
                                                            onChange={e =>
                                                                handleTestChange(
                                                                    e,
                                                                    i
                                                                )
                                                            }
                                                            sx={{
                                                                width: {
                                                                    xs: '100%',
                                                                    sm: '90%',
                                                                    lg: '316px',
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider
                                                    orientation='horizontal'
                                                    variant='middle'
                                                    flexItem
                                                    sx={{ mb: 2 }}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                </Grid>
                                <Box textAlign='center' my={2}>
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        startIcon={<Add />}
                                        onClick={addMoreTest}>
                                        Add More tests
                                    </Button>
                                </Box>
                            </TabPanel>
                            {department.assessment_fields.length ? (
                                <TabPanel value={tabSelected} index={2}>
                                    {department.assessment_fields.map(
                                        (field, i) => (
                                            <Grid
                                                container
                                                columnSpacing={5}
                                                rowSpacing={1}
                                                alignItems='center'
                                                mb={2}
                                                key={i}>
                                                <Grid item xs={12} lg>
                                                    <Typography
                                                        variant='body2'
                                                        fontWeight={600}
                                                        textTransform='capitalize'>
                                                        {field.name}
                                                    </Typography>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={12}
                                                    lg={10}
                                                    display='flex'
                                                    spacing={2}>
                                                    <Input
                                                        name={'$' + field.name}
                                                        variant='outlined'
                                                        size='small'
                                                        multiline
                                                        rows={4}
                                                        type={field.datatype}
                                                        sx={{
                                                            width: {
                                                                xs: '100%',
                                                                sm: '90%',
                                                                lg: '316px',
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                    )}
                                </TabPanel>
                            ) : null}
                        </Box>
                    </Box>
                    <Stack
                        direction='row'
                        p={2}
                        spacing={2}
                        sx={{
                            boxShadow: '0 -4px 5px -3px rgba(0,0,0,.1);',
                            backgroundColor: 'background.paper',
                            zIndex: 1100,
                            position: 'fixed',
                            bottom: 0,
                            width: {
                                xs: '100%',
                                xm: `calc(100% - 280px)`,
                            },
                        }}>
                        <Submit>
                            {loader => (
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    type='submit'
                                    disabled={Boolean(loader)}
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    endIcon={loader}>
                                    Save
                                </Button>
                            )}
                        </Submit>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={savedAsDraft}
                            sx={{
                                textTransform: 'capitalize',
                            }}>
                            Save as draft
                        </Button>
                        <Button
                            onClick={() => {
                                navigate(-1);
                            }}
                            sx={{
                                bgcolor: 'background.default',
                                border: '1px solid',
                                borderColor: 'divider',
                            }}>
                            Cancel
                        </Button>
                    </Stack>
                </Form>
            )}
        </Box>
    );
};

export default NewAssessment;
