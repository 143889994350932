import React, { useEffect, useState, useCallback } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { server } from '../../utils/axios';
import useErrorHandler from '../../hooks/useErrorHandler';
import { formatDate } from '../../utils/function';

const columns = [
    { id: 'sno', label: 'S. No.', minWidth: 50 },
    { id: 'therapist', label: 'Therapist', minWidth: 150 },
    {
        id: 'session_count',
        label: 'Sessions Completed',
        minWidth: 150,
        align: 'center',
    },
    { id: 'duration', label: 'Total Duration', minWidth: 150, align: 'center' },
];

const RecentAttendance = ({ dateRange }) => {
    const errorHandler = useErrorHandler();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();

    const fetchSessionsData = useCallback(async () => {
        try {
            const params = {
                start_date: formatDate(dateRange?.startDate),
                end_date: formatDate(dateRange?.endDate),
            };
            const response = await server.get('/session-therapist/', {
                params,
            });
            const sessionsData = response.data.users_sessions_hours;

            const formattedData = sessionsData.map((session, index) => ({
                sno: index + 1,
                therapist: session.username,
                therapistId: session.id, // assuming `session.id` exists
                session_count: session.session_count,
                duration: session.duration || 'N/A',
            }));

            setRows(formattedData);
        } catch (error) {
            errorHandler(error);
        }
    }, [errorHandler, dateRange]);

    useEffect(() => {
        fetchSessionsData();
    }, [fetchSessionsData]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRowClick = therapistId => {
        navigate(`/users/${therapistId}`);
    };

    return (
        <>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}>
                                    <Typography variant='caption'>
                                        {column.label}
                                    </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map(row => (
                                <TableRow
                                    hover
                                    role='checkbox'
                                    tabIndex={-1}
                                    key={row.sno}
                                    onClick={() =>
                                        handleRowClick(row.therapistId)
                                    }
                                    style={{ cursor: 'pointer' }}>
                                    {columns.map(column => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}>
                                                <Typography variant='body2'>
                                                    {value}
                                                </Typography>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component='div'
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

export default RecentAttendance;
