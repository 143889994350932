import {
    Box,
    Container,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import * as React from 'react';
import Image from '../../../../components/Image';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocalPostOfficeOutlinedIcon from '@mui/icons-material/LocalPostOfficeOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const Primary = '#2d7c7c';
const Secondary = '#7bc24d';

const rows = [
    { headerName: 'Name:', field: 'name' },
    { headerName: 'DOB/Age:', field: 'dob' },
    { headerName: 'Date of Testing:', field: 'date_of_assessment' },
    { headerName: 'Gender:', field: 'gender' },
    { headerName: 'Presenting complaints:', field: 'presenting_complaints' },
    { headerName: 'Parental History:', field: 'parental_history' },
    { headerName: 'Family History:', field: 'family_history' },
    { headerName: 'Development History:', field: 'development_history' },
    { headerName: 'School/Therapy History:', field: 'school_history' },
    { headerName: 'Tests Administered:', field: 'test_results' },
    { headerName: 'Behavioral Observation:', field: 'behavioural_observation' },
    { headerName: 'Test Results:', field: 'test_results' },
    { headerName: 'Impression:', field: 'impression' },
    { headerName: 'Recommendations:', field: 'recommendations' },
];

// Function to dynamically set title based on department
const getReportTitle = department => {
    switch (department?.name) {
        case 'BT':
            return 'Psychological Report';
        case 'ST':
            return 'Speech and Language Development History';
        case 'OT':
            return 'Occupational Therapy Initial Evaluation';
        default:
            return 'Assessment Report';
    }
};

const AssessmentPDF = React.forwardRef((props, ref) => {
    const { assessment } = props;
    const reportTitle = getReportTitle(assessment.department); // Dynamically get title

    return (
        <Container
            id='container'
            maxWidth='md'
            ref={ref}
            sx={{
                py: 5,
                px: 5,
                display: 'flex',
                flexDirection: 'column',
                height: '100dvh',
                '@page': {
                    margin: '16px 0',
                    borderBottom: `17px solid ${Primary}`,
                },
            }}>
            <Grid container spacing={1} alignItems='baseline' mb={4}>
                <Grid item>
                    <Image name='logo-dark.png' sx={{ height: 100 }} />
                </Grid>
                <Grid item xs>
                    <Box
                        sx={{
                            height: 5,
                            width: '100%',
                            background: Secondary,
                            mb: 0.4,
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Box
                        sx={{
                            height: 5,
                            width: '100%',
                            background: Primary,
                            mb: 0.4,
                        }}
                    />
                </Grid>
            </Grid>
            {/* Dynamic Title */}
            <Box bgcolor={Primary} p={1.5} textAlign='center'>
                <Typography
                    variant='h5'
                    fontWeight={700}
                    color='white'
                    textTransform='uppercase'>
                    {reportTitle} {/* Dynamic Title Rendering */}
                </Typography>
            </Box>
            <Box flexGrow={1}>
                <Table>
                    <TableBody>
                        {rows.map(row => (
                            <TableRow key={row.headerName}>
                                <TableCell
                                    sx={{
                                        border: '1px solid #2d7c7c',
                                        borderLeft: 'none',
                                        maxWidth: '200px',
                                        width: '200px',
                                        p: 1,
                                    }}>
                                    <Typography
                                        variant='body2'
                                        fontWeight={700}>
                                        {row.headerName}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: '1px solid #2d7c7c',
                                        borderRight: 'none',
                                        p: 1,
                                    }}>
                                    <Typography
                                        variant='body2'
                                        fontWeight={500}>
                                        {assessment[row.field] || '-'}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}

                        {/* Render extra fields */}
                        {assessment.extra_fields &&
                            Object.keys(assessment.extra_fields).map(key => (
                                <TableRow key={key}>
                                    <TableCell
                                        sx={{
                                            border: '1px solid #2d7c7c',
                                            borderLeft: 'none',
                                            maxWidth: '200px',
                                            width: '200px',
                                            p: 1,
                                        }}>
                                        <Typography
                                            variant='body2'
                                            fontWeight={700}>
                                            {key}:
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: '1px solid #2d7c7c',
                                            borderRight: 'none',
                                            p: 1,
                                        }}>
                                        <Typography
                                            variant='body2'
                                            fontWeight={500}>
                                            {assessment.extra_fields[key] ||
                                                '-'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <Box
                    sx={{
                        height: 5,
                        width: '100%',
                        background: Secondary,
                        mb: 0.4,
                    }}
                />
            </Box>
            {/* Footer */}
            <Stack
                className='footer'
                direction='row'
                alignItems='flex-start'
                spacing={2}
                mt={4}
                maxWidth='55%'
                fontWeight={600}>
                <Stack direction='column'>
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <LocalPhoneIcon sx={{ color: Primary, fontSize: 18 }} />
                        <Typography variant='body2'>85000 02850</Typography>
                    </Stack>
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <LocalPostOfficeOutlinedIcon
                            sx={{ color: Primary, fontSize: 18 }}
                        />
                        <Typography variant='body2'>
                            care@shapingcdc.com
                        </Typography>
                    </Stack>
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <LanguageOutlinedIcon
                            sx={{ color: Primary, fontSize: 18 }}
                        />
                        <Typography variant='body2'>
                            www.shapingcdc.com
                        </Typography>
                    </Stack>
                </Stack>
                <Stack direction='row' alignItems='flex-start' spacing={1}>
                    <LocationOnOutlinedIcon
                        sx={{ color: Primary, fontSize: 18 }}
                    />
                    <Typography variant='body2'>
                        Plot No.1050/1, 1" Floor. Opp. SMR Vinay Fountainhead,
                        Miyapur, Hyderabad-49.
                    </Typography>
                </Stack>
            </Stack>
        </Container>
    );
});

export default AssessmentPDF;
