import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Container,
    Typography,
    Divider,
    Grid,
    Button,
    CircularProgress,
} from '@mui/material';
import DataGrid from '../components/DataGrid';
import { GridToolbarContainer } from '@mui/x-data-grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchBar from '../components/SearchBar';
import { useNavigate } from 'react-router-dom';
import useErrorHandler from '../hooks/useErrorHandler';
import useLoader from '../hooks/useLoader';
import { server } from '../utils/axios';
import green from '@mui/material/colors/green';
import { useMessage } from '../components/Header';

const Index = () => {
    const [rows, setRows] = useState([]);
    const [search, setSearch] = useState('');
    const { showSuccess, showError } = useMessage();
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });
    const [rowCount, setRowCount] = useState(0);
    const { start, end, loaderState } = useLoader();
    const errorHandler = useErrorHandler();
    const navigate = useNavigate();

    const [loadingRows, setLoadingRows] = useState({});

    const getAssessments = useCallback(async () => {
        start();
        setRows([]);

        const params = {
            limit: paginationModel.pageSize,
            offset: (paginationModel.page + 1 - 1) * paginationModel.pageSize,
            expand: 'client,department',
        };

        if (search) params.search = search;

        try {
            const response = await server.get(`/assessments/`, { params });
            const assessments = response.data;

            setRows(assessments.results);
            setRowCount(assessments.count);
        } catch (e) {
            errorHandler(e);
        } finally {
            end();
        }
    }, [paginationModel, search, start, end, errorHandler]);

    const sendEmail = async (event, id) => {
        event.stopPropagation();

        setLoadingRows(prevState => ({
            ...prevState,
            [id]: true,
        }));

        try {
            const response = await server.get(`/send-email/${id}/`);

            if (response.status === 200) {
                showSuccess(response.data.message);

                setRows(prevRows =>
                    prevRows.map(row =>
                        row.id === id ? { ...row, email_sent: true } : row
                    )
                );
            } else {
                showError('Failed to send email');
            }
        } catch (error) {
            console.error('Error sending email:', error);
        } finally {
            setLoadingRows(prevState => ({
                ...prevState,
                [id]: false,
            }));
        }
    };

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            sortable: false,
            width: 50,
            valueGetter: params => `${params.row.id}`,
        },
        {
            field: 'name',
            headerName: 'Name',
            sortable: false,
            width: 120,
            renderCell: params => (
                <Typography
                    variant='subtitle2'
                    fontWeight='600'
                    sx={{ color: 'primary.main', padding: '0 8px' }}>
                    {params.row.client.name}
                </Typography>
            ),
        },
        {
            field: 'department',
            headerName: 'Department',
            sortable: false,
            width: 120,
            renderCell: params => (
                <Typography variant='body2' sx={{ padding: '0 8px' }}>
                    {params.row.department.name}
                </Typography>
            ),
        },
        {
            field: 'email_sent',
            headerName: 'Email Sent',
            sortable: false,
            width: 100,
            renderCell: params => {
                if (params.row.email_sent) {
                    return (
                        <CheckCircleIcon sx={{ color: green['A700'], mx: 1 }} />
                    );
                }
                const isLoading = loadingRows[params.row.id];
                return (
                    <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        disabled={isLoading}
                        onClick={event => sendEmail(event, params.row.id)}
                        sx={{ padding: '4px 8px', fontSize: '0.8rem' }}>
                        {isLoading ? (
                            <CircularProgress size={20} />
                        ) : (
                            'Send Email'
                        )}
                    </Button>
                );
            },
        },
        {
            field: 'date_of_assessment',
            headerName: 'Date Of Assessment',
            sortable: false,
            width: 180,
            renderCell: params => (
                <Typography variant='body2' sx={{ padding: '0 8px' }}>
                    {params.row.date_of_assessment}
                </Typography>
            ),
        },
        {
            field: 'test_results',
            headerName: 'Test Results',
            sortable: false,
            flex: 1,
            renderCell: params => (
                <Typography variant='body2' sx={{ padding: '0 8px' }}>
                    {params.row.test_results}
                </Typography>
            ),
        },
    ];

    useEffect(() => {
        getAssessments();
    }, [getAssessments]);

    return (
        <Container maxWidth='false'>
            <Box pt={3}>
                <Typography variant='h4' fontWeight={500}>
                    Assessments
                </Typography>
                <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{ wordSpacing: '2px' }}>
                    Elevate your assessments with precision.
                </Typography>
                <Divider variant='fullWidth' sx={{ mt: 2, mb: 4 }} />
            </Box>
            <DataGrid
                rows={rows}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                paginationMode='server'
                pageSizeOptions={[5, 10, 20, 50, 100, 1000]}
                autoHeight
                onRowClick={data =>
                    navigate(`/clients/${data.row.client.id}/assessment`)
                }
                disableSelectionOnClick
                loading={loaderState}
                rowCount={rowCount}
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                    toolbar: { setSearch, search },
                }}
                checkboxSelection={false}
            />
        </Container>
    );
};

export function CustomToolbar({ setSearch, search }) {
    return (
        <GridToolbarContainer sx={{ pb: '24px' }}>
            <Grid container alignItems='center' width='100%' spacing={1}>
                <Grid item xs>
                    <SearchBar
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                </Grid>
            </Grid>
        </GridToolbarContainer>
    );
}

export default Index;
