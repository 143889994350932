import React, { useCallback, useEffect, useState } from 'react';
import { Box, Container, Typography, Grid, Divider } from '@mui/material';
import DataGrid from '../components/DataGrid';
import { GridToolbarContainer } from '@mui/x-data-grid';
import SearchBar from '../components/SearchBar';
import { useNavigate } from 'react-router-dom';
import useLoader from '../hooks/useLoader';
import useErrorHandler from '../hooks/useErrorHandler';
import { server } from '../utils/axios';

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 50,
        valueGetter: params => `${params.row.goal.id}`,
    },
    {
        field: 'client',
        headerName: 'Client',
        width: 150,
        renderCell: params => (
            <Typography variant='subtitle2' fontWeight={600} color='primary'>
                {params.row.client.name}
            </Typography>
        ),
    },
    {
        field: 'goal',
        headerName: 'Goal',
        width: 200,
        renderCell: params => params.row.goal.goal,
    },
    {
        field: 'setDate',
        headerName: 'Set Date',
        width: 100,
        renderCell: params =>
            new Date(params.row.set_date).toLocaleDateString('en-GB'),
    },
    {
        field: 'targetDate',
        headerName: 'TGT Date',
        width: 100,
        renderCell: params =>
            new Date(params.row.set_date).toLocaleDateString('en-GB'),
    },
    { field: 'remark', headerName: 'Remark', width: 300 },
];

const Index = () => {
    const [rows, setRows] = useState([]);
    const [search, setSearch] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });
    const { start, end, loaderState } = useLoader();
    const errorHandler = useErrorHandler();
    const navigate = useNavigate();

    const getClientGoals = useCallback(async () => {
        start();
        try {
            const { data } = await server.get('/client-goals/', {
                params: {
                    limit: paginationModel.pageSize,
                    offset: paginationModel.page * paginationModel.pageSize,
                    ...(search && { search }),
                },
            });
            setRows(data.results);
            setPaginationModel(prev => ({ ...prev, rowCount: data.count }));
        } catch (e) {
            errorHandler(e);
        } finally {
            end();
        }
    }, [
        paginationModel.pageSize,
        paginationModel.page,
        search,
        start,
        end,
        errorHandler,
    ]);

    useEffect(() => {
        getClientGoals();
    }, [getClientGoals]);

    return (
        <Container maxWidth='false'>
            <Box pt={3}>
                <Typography variant='h4' fontWeight={500}>
                    Client Goals
                </Typography>
                <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{ wordSpacing: '2px' }}>
                    Empower your clients toward achievement.
                </Typography>
                <Divider sx={{ mt: 2, mb: 4 }} />
            </Box>
            <DataGrid
                rows={rows}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                paginationMode='server'
                autoHeight
                onRowClick={({ row }) =>
                    navigate(`/clients/${row.client.id}/goals`)
                }
                disableSelectionOnClick
                loading={loaderState}
                rowCount={paginationModel.rowCount}
                slots={{ toolbar: CustomToolbar }}
                slotProps={{ toolbar: { setSearch, search } }}
            />
        </Container>
    );
};

const CustomToolbar = ({ setSearch, search }) => (
    <GridToolbarContainer sx={{ pb: '24px' }}>
        <Grid container alignItems='center' spacing={1}>
            <Grid item xs>
                <SearchBar
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
            </Grid>
        </Grid>
    </GridToolbarContainer>
);

export default Index;
