import {
    Box,
    Divider,
    Stack,
    Typography,
    Skeleton,
    IconButton,
    Menu,
    Button,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import useErrorHandler from '../../../../../hooks/useErrorHandler';
import { useCallback, useEffect, useState } from 'react';
import useLoader from '../../../../../hooks/useLoader';
import DataGrid from '../../../../../components/DataGrid';
import { DateTime } from 'luxon';
import { useMenu } from '../../../../../hooks/useMenu';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { server } from '../../../../../utils/axios';
import dayjs from 'dayjs';
import VideoCameraBackOutlinedIcon from '@mui/icons-material/VideoCameraBackOutlined';

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        width: 50,
        valueGetter: params => `${params.row.id}`,
    },
    {
        field: 'client_goal_name',
        headerName: 'Goal',
        sortable: false,
        width: 150,
        renderCell: params => (
            <Typography
                variant='subtitle2'
                fontWeight='600'
                sx={{ color: 'primary.main' }}>
                {params.row.client_goal_name}
            </Typography>
        ),
    },

    {
        field: 'client_name',
        headerName: 'Therapist',
        width: 100,
        renderCell: params =>
            params.row.user_first_name + ' ' + params.row.user_last_name,
    },
    {
        field: 'activity_name',
        headerName: 'Activity',
        width: 100,
    },
    {
        field: 'start_time',
        headerName: 'From Time',
        width: 100,
    },
    {
        field: 'end_time',
        headerName: 'To Time',
        width: 100,
        renderCell: params =>
            params.row.end_time ? params.row.end_time : 'N/A',
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 100,
    },

    {
        headerName: 'Duration',
        width: 100,
        renderCell: params => {
            if (params.row.start_time && params.row.end_time) {
                const endTime = DateTime.fromISO(params.row.end_time);
                const startTime = DateTime.fromISO(params.row.start_time);
                const duration = endTime.diff(startTime).toFormat('hh:mm:ss');

                return duration;
            } else {
                return 'N/A';
            }
        },
    },
    {
        field: 'observation',
        headerName: 'Observation',
        width: 100,
        renderCell: params =>
            params.row.end_time ? params.row.observation : 'N/A',
    },
    {
        field: 'Score',
        headerName: 'Score',
        flex: 1,
        renderCell: params => (params.row.score ? params.row.score : 'N/A'),
    },
    {
        field: 'video_url',
        headerName: 'Video',
        width: 100,
        sortable: false,
        renderCell: params =>
            params.row.video ? (
                <IconButton
                    color='primary'
                    onClick={() =>
                        downloadVideo(
                            params.row.video,
                            `session-${params.row.id}.mp4`
                        )
                    }>
                    <VideoCameraBackOutlinedIcon />
                </IconButton>
            ) : (
                'N/A'
            ),
    },
];

const downloadVideo = (videoUrl, fileName) => {
    const link = document.createElement('a');
    link.href = videoUrl;
    link.download = fileName || 'video.mp4';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const Index = () => {
    const { clientId, departmentId } = useParams();
    const [rows, setRows] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });
    const [rowCount, setRowCount] = useState(0);
    const { start, end, loaderState } = useLoader();
    const errorHandler = useErrorHandler();
    const [department, setDepartment] = useState(null);
    const navigate = useNavigate();
    const { anchorEl, openMenu, closeMenu } = useMenu();
    const [interval, setInterval] = useState({ from: null, to: null });

    const getSessions = useCallback(
        async (dateInterval = {}) => {
            start();
            setRows([]);

            const params = {
                client_id: clientId,
                department_id: departmentId,
                limit: paginationModel.pageSize,
                offset:
                    (paginationModel.page + 1 - 1) * paginationModel.pageSize,
            };

            if (dateInterval.from && dateInterval.to) {
                params.date__gte = dateInterval.from;
                params.date__lte = dateInterval.to;
            }

            try {
                const response = await server.get(`/sessions/`, { params });
                const sessions = response.data;
                setRows(sessions.results);
                setRowCount(sessions.count);
            } catch (e) {
                errorHandler(e);
            } finally {
                end();
            }
        },
        [clientId, paginationModel, departmentId, start, end, errorHandler]
    );

    const getDepartment = useCallback(
        async departmentId => {
            try {
                const response = await server.get(
                    `/departments/${departmentId}`
                );
                setDepartment(response.data);
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler]
    );

    useEffect(() => {
        getSessions();
    }, [getSessions]);

    useEffect(() => {
        if (departmentId) getDepartment(departmentId);
    }, [departmentId, getDepartment]);

    return (
        <Box p={2}>
            <Stack
                direction='row'
                mb={2}
                justifyContent='space-between'
                alignItems='center'>
                {department ? (
                    <Typography variant='h6'>{department.name}</Typography>
                ) : (
                    <Skeleton variant='text' width={250} height={40} />
                )}
                <Box>
                    <IconButton
                        sx={{ ml: 2 }}
                        color='text.tertiary'
                        onClick={openMenu}>
                        <FilterAltOutlinedIcon />
                    </IconButton>
                    <Menu
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={closeMenu}
                        sx={{
                            '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
                                width: 'min(100%, 212px)',
                                boxShadow:
                                    'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
                                border: '1px solid #00000017',
                                p: 1.5,
                            },
                        }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box px={1}>
                                <Typography
                                    variant='body2'
                                    fontWeight={500}
                                    mb={1}>
                                    From
                                </Typography>
                                <DatePicker
                                    slotProps={{
                                        textField: { size: 'small' },
                                    }}
                                    value={dayjs(interval.from)}
                                    sx={{ mb: 1 }}
                                    onChange={value =>
                                        setInterval({
                                            ...interval,
                                            from: `${value?.$y}-${
                                                value?.$M + 1
                                            }-${value?.$D}`,
                                        })
                                    }
                                />
                                <Typography
                                    variant='body2'
                                    fontWeight={500}
                                    mb={1}>
                                    To
                                </Typography>
                                <DatePicker
                                    slotProps={{
                                        textField: { size: 'small' },
                                    }}
                                    value={dayjs(interval.to)}
                                    sx={{ mb: 1 }}
                                    onChange={value =>
                                        setInterval({
                                            ...interval,
                                            to: `${value?.$y}-${
                                                value?.$M + 1
                                            }-${value?.$D}`,
                                        })
                                    }
                                />
                            </Box>
                            <Divider variant='middle' />
                            <Box mt={1.5} textAlign='right' px={1}>
                                <Button
                                    variant='outlined'
                                    size='small'
                                    sx={{ p: '3px 14px', fontSize: '12px' }}
                                    disabled={!(interval.from && interval.to)}
                                    onClick={() => {
                                        closeMenu();
                                        getSessions(interval);
                                    }}>
                                    Apply
                                </Button>
                            </Box>
                        </LocalizationProvider>
                    </Menu>
                </Box>
            </Stack>

            <Divider variant='fullWidth' sx={{ mb: 2 }} />

            <DataGrid
                rows={rows}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                onRowClick={params => {
                    if (!params.row.end_time) navigate(`edit/${params.id}`);
                }}
                paginationMode='server'
                pageSizeOptions={[5, 10, 20, 50, 100, 1000]}
                autoHeight
                rowSelection={false}
                checkboxSelection={false}
                disableRowSelectionOnClick={true}
                loading={loaderState}
                rowCount={rowCount}
            />
        </Box>
    );
};

export default Index;
