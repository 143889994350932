import {
    MenuItem,
    Select,
    TextField,
    Stack,
    Divider,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    IconButton,
} from '@mui/material';
import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Submit, useForm } from '../../../hooks/useForm';
import { Input } from '../../../hooks/useForm/inputs/index';
import { useMessage } from '../../../components/Header';
import Loading from '../../../components/Loading';
import { useParams } from 'react-router-dom';
import useErrorHandler from '../../../hooks/useErrorHandler';
import { server } from '../../../utils/axios';
import useLoader from '../../../hooks/useLoader';
import EditIcon from '@mui/icons-material/Edit';
import { useUser } from '../../../hooks/Authorize';

const ClientActivity = () => {
    const { goalId } = useParams();
    const { clientId } = useParams();
    const user = useUser();
    const { showSuccess } = useMessage();
    const [activities, setActivities] = useState({});
    const [clientActivity, setClientActivity] = useState([]);
    const errorHandler = useErrorHandler();
    const { loaderState } = useLoader();
    const [editId, setEditId] = useState(null);
    const [goalTitle, setGoalTitle] = useState('');

    const handlers = useForm(
        useMemo(
            () => ({
                client_goal: { value: '', final: v => goalId },

                set_date: {
                    required: true,
                    value: new Date().toISOString().slice(0, 10),
                },
                target_date: {
                    required: true,
                    value: new Date().toISOString().slice(0, 10),
                },
                status: { required: true, value: 'Active' },

                activity: { value: [] },
            }),
            [goalId]
        ),
        { Input: TextField }
    );

    const customChangeHandler = e => {
        const { name, value } = e.target;
        handlers.setValues({ [name]: value });
    };

    const onSubmit = response => {
        if (response.data) {
            fetchClientActivities();
            showSuccess('Activity is successfully assigned to the Goal');
            setEditId(null);
        }
    };

    const getActivities = useCallback(async () => {
        try {
            const params = {
                department: user.department,
            };

            const response = await server.get(`/activities/`, { params });

            setActivities({});
            const activities = response.data.results;

            const format = {};

            activities.forEach(
                activity => (format[activity.id] = activity.name)
            );

            setActivities(format);
        } catch (e) {
            errorHandler(e);
        }
    }, [errorHandler, user]);

    const fetchClientActivities = useCallback(async () => {
        try {
            const response = await server.get(`/client-activity/`, {
                params: {
                    client_goal: goalId,
                    department: user.department,
                },
            });
            const clientActivities = response.data;

            setClientActivity(clientActivities);
        } catch (e) {
            errorHandler(e);
        }
    }, [goalId, errorHandler, user.department]);

    const editGoal = activity => {
        setEditId(activity.id);
        handlers.setValues({
            client_goal: activity.client_goal || '',
            set_date: new Date(activity.set_date).toISOString().slice(0, 10),
            target_date: new Date(activity.target_date)
                .toISOString()
                .slice(0, 10),
            status: activity.status || '',
            activity: activity.activity || [],
        });
    };

    const getClientGoals = useCallback(async () => {
        const params = {
            client_id: clientId,
        };

        try {
            const response = await server.get(`/client-goals/`, { params });
            const clientGoals = response.data;
            if (goalId) {
                const goal = clientGoals.results.find(
                    item => item.id.toString() === goalId
                );
                if (goal) {
                    setGoalTitle(goal.goal.goal);
                }
            }
        } catch (e) {
            errorHandler(e);
        }
    }, [clientId, errorHandler, goalId]);

    useEffect(() => {
        getActivities();
    }, [getActivities]);

    useEffect(() => {
        getClientGoals();
    }, [getClientGoals]);

    useEffect(() => {
        fetchClientActivities();
    }, [fetchClientActivities]);

    return (
        <Box
            bgcolor='background.paper'
            height='calc(100vh - 76px)'
            overflow='auto'
            display='flex'
            flexDirection='column'
            p={0}>
            <Typography variant='h5' fontWeight={600} color='primary' p={2}>
                {goalId && `Assign Activity ${goalTitle}`}
            </Typography>
            <Divider variant='fullWidth' />
            {loaderState ? (
                <Loading message='Please wait, while your credentials are loading...' />
            ) : (
                <Form
                    handlers={handlers}
                    onSubmit={onSubmit}
                    action={
                        editId
                            ? `client-activity/${editId}`
                            : 'client-activity/'
                    }
                    method={editId ? 'patch' : 'post'}
                    axiosInstance={server}
                    onError={errorHandler}
                    style={{ flexGrow: 1, position: 'relative' }}>
                    <Box
                        p={2}
                        display='flex'
                        flexDirection='column'
                        mb={4}
                        overflow='auto'>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Assign Activities
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10}>
                                <Select
                                    name='activity'
                                    onChange={customChangeHandler}
                                    value={handlers.values.activity}
                                    displayEmpty
                                    sx={{ width: 316 }}>
                                    {Object.keys(activities).map(activityId => (
                                        <MenuItem
                                            value={parseInt(activityId)}
                                            key={activityId}
                                            sx={{ px: 1.2 }}>
                                            {activities[activityId]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Set Date
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10}>
                                <Input
                                    name='set_date'
                                    variant='outlined'
                                    type='date'
                                    sx={{ width: 316 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Target Date
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10}>
                                <Input
                                    name='target_date'
                                    variant='outlined'
                                    type='date'
                                    sx={{ width: 316 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Status
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10}>
                                <Select
                                    value={handlers.values.status}
                                    fullWidth
                                    name='status'
                                    onChange={customChangeHandler}
                                    sx={{ width: 316 }}>
                                    <MenuItem value='Active'>Active</MenuItem>
                                    <MenuItem value='Inactive'>
                                        Inactive
                                    </MenuItem>
                                </Select>
                            </Grid>
                        </Grid>

                        <Divider sx={{ my: 5 }} />

                        <Table sx={{ mb: 5 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Activity</TableCell>
                                    <TableCell>Set Date</TableCell>
                                    <TableCell>Target Date</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clientActivity.map(activity => (
                                    <TableRow
                                        selected={activity.id === editId}
                                        key={activity.id}>
                                        <TableCell>{activity.id}</TableCell>
                                        <TableCell>
                                            {activities[activity.activity]}
                                        </TableCell>
                                        <TableCell>
                                            {new Date(activity.set_date)
                                                .toISOString()
                                                .slice(0, 10)}
                                        </TableCell>
                                        <TableCell>
                                            {new Date(activity.target_date)
                                                .toISOString()
                                                .slice(0, 10)}
                                        </TableCell>
                                        <TableCell>{activity.status}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={() =>
                                                    editGoal(activity)
                                                }>
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                    <Stack
                        direction='row'
                        p={2}
                        spacing={2}
                        sx={{
                            boxShadow: '0 -4px 5px -3px rgba(0,0,0,.1);',
                            backgroundColor: 'background.paper',
                            zIndex: 1100,
                            position: 'fixed',
                            bottom: 0,
                            width: {
                                xs: '100%',
                                xm: `calc(100% - 280px)`,
                            },
                        }}>
                        <Submit>
                            {loader => (
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    type='submit'
                                    disabled={Boolean(loader)}
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}>
                                    {editId ? 'Edit Activity' : 'Add Activity'}
                                </Button>
                            )}
                        </Submit>
                    </Stack>
                </Form>
            )}
        </Box>
    );
};

export default ClientActivity;
