import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './utils/axios';
import AuthorizeRouter from './router/AuthorizeRouter';
import Header from './components/Header';

// Client
import Clients from './pages/Clients';
import Client from './pages/Clients/pages/client';
import NewClient from './pages/Clients/pages/NewClient';
import Overview from './pages/Clients/pages/client/Overview';
import Assessment from './pages/Clients/pages/client/Assessment';
import Sessions from './pages/Clients/pages/client/Sessions';
import DepartmentSessions from './pages/Clients/pages/client/Sessions/Department';
import Goals from './pages/Clients/pages/client/Goals';
import NewSession from './pages/Clients/pages/NewSession';
import NewAssessment from './pages/Clients/pages/NewAssessment';
import NewGoal from './pages/Clients/pages/NewGoal';

// Default Pages
import Assessments from './pages/Assessments';
import Session from './pages/Session';
import ClientGoals from './pages/ClientGoals';
import Dashboard from './pages/Dashboard';
import Login from './pages/auth/Login';

// Admin Pages
import Category from './pages/admin/GoalCategory';
import NewCategory from './pages/admin/GoalCategory/NewCategory';
import Goal from './pages/admin/Goal';
import Activity from './pages/admin/Activity';
import Activities from './pages/admin/Goal/Activity';
import Users from './pages/admin/Users';
import NewUser from './pages/admin/Users/NewUser';
import NewActivity from './pages/admin/Activity/NewActivity';
import AdminNewGoal from './pages/admin/Goal/NewGoal';
import Department from './pages/admin/Department';
import NewDepartment from './pages/admin/Department/NewDepartment';
import Forms from './pages/admin/Forms';
import AssignActivity from './pages/Clients/pages/AssignActivity';

// import ClientActivities from './pages/Clients/pages/client/Activities';

const App = () => {
    return (
        <Header>
            <Routes>
                <Route path='/' element={<AuthorizeRouter />}>
                    <Route index element={<Dashboard />} />

                    {/* Clients and Client Paths */}
                    <Route path='clients'>
                        <Route index element={<Clients />} />
                        <Route path='new' element={<NewClient />} />
                        <Route path='edit/:clientId' element={<NewClient />} />
                        <Route path=':clientId' element={<Client />}>
                            <Route index element={<Overview />} />
                            <Route path='sessions'>
                                <Route index element={<Sessions />} />
                                <Route
                                    path='department/:departmentId'
                                    element={<DepartmentSessions />}
                                />
                            </Route>
                            <Route path='assessment' element={<Assessment />} />
                            {/* <Route
                                path='activities'
                                element={<ClientActivities />}
                            /> */}
                            <Route path='goals' element={<Goals />} />
                        </Route>
                        <Route
                            path=':clientId/sessions/new'
                            element={<NewSession />}
                        />
                        <Route
                            path=':clientId/sessions/department/:departmentId/edit/:sessionId'
                            element={<NewSession />}
                        />
                        <Route
                            path=':clientId/sessions/edit/:sessionId'
                            element={<NewSession />}
                        />
                        <Route
                            path=':clientId/assessment/new'
                            element={<NewAssessment />}
                        />
                        <Route
                            path=':clientId/assessment/draft/:draftId'
                            element={<NewAssessment />}
                        />
                        <Route
                            path=':clientId/goals/new'
                            element={<NewGoal />}
                        />
                        <Route
                            path=':clientId/goals/:goalId'
                            element={<NewGoal />}
                        />
                        <Route
                            path=':clientId/goals/:goalId/activity'
                            element={<AssignActivity />}
                        />
                        <Route
                            path=':clientId/goals/edit/:goalId'
                            element={<NewGoal />}
                        />
                    </Route>

                    <Route path='assessments' element={<Assessments />} />
                    <Route path='session' element={<Session />} />
                    <Route path='client-goals' element={<ClientGoals />} />

                    <Route path='/users'>
                        <Route index element={<Users />} />
                        <Route path='new' element={<NewUser />} />
                        <Route path=':userId' element={<NewUser />} />
                    </Route>

                    <Route path='/master'>
                        <Route path='category'>
                            <Route index element={<Category />} />
                            <Route path='new' element={<NewCategory />} />
                            <Route
                                path=':categoryId'
                                element={<NewCategory />}
                            />
                        </Route>
                        <Route path='goal'>
                            <Route index element={<Goal />} />
                            <Route
                                path=':goalId/activity'
                                element={<Activities />}
                            />
                            <Route path='new' element={<AdminNewGoal />} />
                            <Route path=':goalId' element={<AdminNewGoal />} />
                        </Route>
                        <Route path='activity'>
                            <Route index element={<Activity />} />
                            <Route path='new' element={<NewActivity />} />
                            <Route
                                path=':activityId'
                                element={<NewActivity />}
                            />
                        </Route>
                        <Route path='department'>
                            <Route index element={<Department />} />
                            <Route path='new' element={<NewDepartment />} />
                            <Route
                                path=':departmentId'
                                element={<NewDepartment />}
                            />
                        </Route>
                        <Route path='forms' element={<Forms />} />
                    </Route>
                </Route>
                <Route path='/login' element={<Login />} />
            </Routes>
        </Header>
    );
};

export default App;
