import React, { useEffect, useState, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { server } from '../../utils/axios';
import useErrorHandler from '../../hooks/useErrorHandler';
import { formatDate } from '../../utils/function';

const columns = [
    { field: 'sno', headerName: 'S. No.', width: 60 },
    { field: 'client', headerName: 'Client', width: 150 },
    {
        field: 'sessions_complete',
        headerName: 'Sessions Completed',
        width: 150,
    },
    { field: 'total_duration', headerName: 'Total Duration', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
];

const ClientTable = ({ dateRange }) => {
    const errorHandler = useErrorHandler();
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState(10);
    const [rows, setRows] = useState([]);

    const fetchSessionData = useCallback(async () => {
        try {
            const params = {
                start_date: formatDate(dateRange?.startDate),
                end_date: formatDate(dateRange?.endDate),
            };

            const sessionResponse = await server.get('/session-clients/', {
                params,
            });
            const sessionData = sessionResponse.data.users_sessions_hours;

            const mergedData = sessionData.map((session, index) => ({
                id: index + 1, // `id` is required by DataGrid
                sno: index + 1,
                client: session.name,
                clientId: session.id, // assuming `session.id` is the unique client identifier
                sessions_complete: session.total_session_count,
                total_duration: session.total_duration || 'N/A',
                status: session.session_in_progress
                    ? 'In Progress'
                    : 'Completed',
            }));

            setRows(mergedData);
        } catch (error) {
            errorHandler(error);
        }
    }, [errorHandler, dateRange]);

    useEffect(() => {
        fetchSessionData();
    }, [fetchSessionData]);

    const handleRowClick = () => {
        navigate(`/client-goals/`);
    };

    return (
        <div style={{ height: 500, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 30]}
                pagination
                onRowClick={handleRowClick}
            />
        </div>
    );
};

export default ClientTable;
