import { Box, Button, Card, TextField, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Form, Submit, useForm } from '../../hooks/useForm/index';
import { Input } from '../../hooks/useForm/inputs/index';
import Image from '../../components/Image';
import { useAuthorize } from '../../hooks/Authorize';
import { setCookie } from '../../utils/cookies';
import { env } from '../../utils/function';
import useErrorHandler from '../../hooks/useErrorHandler';

export default function Login() {
    const authorize = useAuthorize();
    const errorHandler = useErrorHandler();
    const handlers = useForm(
        useMemo(
            () => ({
                username: { required: true },
                password: { required: true },
            }),
            []
        ),
        { Input: TextField }
    );

    const onSubmit = response => {
        const { token, user } = response.data;

        if (token && user) {
            console.log(user);
            setCookie('accessToken', token);
            authorize(true, setUser => setUser(user));
        }
    };

    return (
        <Box
            sx={{
                background: '#F2F2F2',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                flexDirection: 'column',
                py: 2,
            }}>
            <div style={{ marginBottom: '8px' }}>
                <Image name='logo-dark.png' sx={{ maxWidth: '250px' }} />
            </div>
            <Card sx={{ p: 2, maxWidth: '465px', width: '100%', mx: 2 }}>
                <Box sx={{ p: { xs: 2, sm: 4 } }}>
                    <Typography variant='h4' fontWeight={500} textAlign='center' gutterBottom>
                        Log in
                    </Typography>
                    <Typography
                        variant='body2'
                        sx={{ color: 'rgba(0, 0, 0, 0.5)', mb: 7 }}
                        textAlign='center'>
                        Enter your credentials to login your account.
                    </Typography>

                    <Form
                        handlers={handlers}
                        onSubmit={onSubmit}
                        method='post'
                        action={`${env('SERVER')}/user/login/`}
                        onError={errorHandler}>
                        <Typography variant='subtitle2' sx={{ fontWeight: '500' }} gutterBottom>
                            Username or email
                        </Typography>
                        <Input
                            variant='outlined'
                            fullWidth
                            name='username'
                            placeholder='username or email'
                            sx={{ mb: 2 }}
                        />
                        <Typography variant='subtitle2' sx={{ fontWeight: ' 500' }} gutterBottom>
                            Password
                            {/*                             <div
                                variant='body2'
                                style={{
                                    float: 'right',
                                    textDecoration: 'none',
                                    color: '#0472D2',
                                    cursor: 'pointer',
                                }}>
                                Forget password?
                            </div> */}
                        </Typography>
                        <Input
                            type='password'
                            variant='outlined'
                            fullWidth
                            name='password'
                            placeholder='password'
                            sx={{ mb: 2 }}
                        />

                        <Submit>
                            {loader => (
                                <Button
                                    type='submit'
                                    variant='contained'
                                    size='large'
                                    fullWidth
                                    sx={{ p: 1.5, mb: 1, mt: 3 }}
                                    disabled={Boolean(loader)}
                                    endIcon={loader}>
                                    Login
                                </Button>
                            )}
                        </Submit>
                    </Form>
                </Box>
            </Card>
        </Box>
    );
}
