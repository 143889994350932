import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import useErrorHandler from '../../../../hooks/useErrorHandler';
import { server } from '../../../../utils/axios';
import Add from '@mui/icons-material/Add';
import DataGrid from '../../../../components/DataGrid';
import useLoader from '../../../../hooks/useLoader';
import { useUser } from '../../../../hooks/Authorize';

const Goals = () => {
    const { clientId } = useParams();
    const [rows, setRows] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });
    const [rowCount, setRowCount] = useState(0);
    const { start, end, loaderState } = useLoader();
    const errorHandler = useErrorHandler();
    const navigate = useNavigate();
    const user = useUser();

    const getClientGoals = useCallback(async () => {
        start();
        setRows([]);

        const params = {
            client_id: clientId,
            limit: paginationModel.pageSize,
            offset: (paginationModel.page + 1 - 1) * paginationModel.pageSize,
            expand: 'activity',
        };

        try {
            const response = await server.get(`/client-goals/`, { params });
            const clientGoals = response.data;

            setRows(clientGoals.results);
            setRowCount(clientGoals.count);
        } catch (e) {
            errorHandler(e);
        } finally {
            end();
        }
    }, [clientId, paginationModel, start, end, errorHandler]);

    useEffect(() => {
        getClientGoals();
    }, [getClientGoals]);

    const columns = [
        {
            field: 'id',
            headerName: 'S.No.',
            sortable: false,
            width: 60,
            valueGetter: params =>
                rows.findIndex(row => params.row.id === row.id) + 1,
        },
        {
            field: 'department',
            headerName: 'DPT',
            width: 60,
        },
        {
            field: 'category',
            headerName: 'Category',
            width: 100,
        },
        {
            field: 'goal',
            headerName: 'Goal',
            width: 200,
            renderCell: params => params.row.goal.goal,
        },
        {
            field: 'setDate',
            headerName: 'Set Date',
            width: 100,
            renderCell: params =>
                new Date(params.row.set_date).toLocaleDateString('en-GB'),
        },
        {
            field: 'targetDate',
            headerName: 'TGT Date',
            width: 100,
            renderCell: params =>
                new Date(params.row.target_date).toLocaleDateString('en-GB'),
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
        },
        {
            field: 'actions',
            headerName: 'actions',
            type: 'actions',
            width: 300,
            getActions: params => [
                <Button
                    onClick={() => navigate(`${params.row.id}`)}
                    size='small'
                    variant='outlined'>
                    Edit Goal
                </Button>,
                <Button
                    onClick={() => navigate(`${params.row.id}/activity`)}
                    size='small'
                    variant='outlined'>
                    Assign Activity
                </Button>,
            ],
        },
    ];

    return (
        <Box p={2}>
            <Stack
                direction='row'
                mb={2}
                justifyContent='space-between'
                alignItems='center'>
                <Typography variant='h6'>Goals</Typography>
                {!user.is_superuser && (
                    <Button
                        variant='contained'
                        color='secondary'
                        sx={{ borderRadius: '6px' }}
                        startIcon={<Add />}
                        onClick={() => navigate('new')}>
                        New
                    </Button>
                )}
            </Stack>

            <Divider variant='fullWidth' sx={{ mb: 2 }} />

            <DataGrid
                rows={rows}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                paginationMode='server'
                pageSizeOptions={[5, 10, 20, 50, 100, 1000]}
                autoHeight
                rowSelection={false}
                disableSelectionOnClick={true}
                loading={loaderState}
                rowCount={rowCount}
                checkboxSelection={false}
            />
        </Box>
    );
};

export default Goals;
