import { TextField, Stack, Divider } from '@mui/material';
import { Box, Button, Grid, Typography, Select, MenuItem } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Submit, useForm } from '../../../hooks/useForm';
import { Input } from '../../../hooks/useForm/inputs/index';
import { useMessage } from '../../../components/Header';
import Loading from '../../../components/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import useErrorHandler from '../../../hooks/useErrorHandler';
import useLoader from '../../../hooks/useLoader';
import { server } from '../../../utils/axios';

const NewGoal = () => {
    const { goalId } = useParams();
    const { showSuccess } = useMessage();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const [categories, setCategories] = useState([]);
    const [departments, setDepartments] = useState([]);
    const { start, end, loaderState } = useLoader();

    const handlers = useForm(
        useMemo(
            () => ({
                department: { required: true },
                category: { required: true },
                goal: { required: true },
                description: { required: true },
            }),
            []
        ),
        { Input: TextField }
    );

    const setValues = handlers.setValues;

    const getGoal = useCallback(async () => {
        start();
        try {
            const response = await server.get(`/goals/${goalId}`);
            const { department, category, goal, description } = response.data;

            setValues({
                department,
                category,
                goal,
                description,
            });
        } catch (e) {
            errorHandler(e);
        } finally {
            end();
        }
    }, [goalId, setValues, start, end, errorHandler]);

    const getCategories = useCallback(async () => {
        try {
            const response = await server.get(`/goal-cat/`);
            const categories = response.data;

            setCategories(categories.results);
        } catch (e) {
            errorHandler(e);
        }
    }, [errorHandler]);

    const getDepartments = useCallback(async () => {
        try {
            const response = await server.get(`/departments/`);
            const departments = response.data;

            setDepartments(departments.results);
        } catch (e) {
            errorHandler(e);
        }
    }, [errorHandler]);

    const onSubmit = res => {
        if (res.data) {
            showSuccess('Goal saved successfully');
            navigate(-1);
        }
    };

    const customChangeHandler = e => {
        const { name, value } = e.target;
        handlers.setValues({ [name]: value });
    };

    useEffect(() => {
        if (goalId) {
            getGoal();
        }
    }, [goalId, getGoal]);

    useEffect(() => {
        getCategories();
    }, [getCategories]);

    useEffect(() => {
        getDepartments();
    }, [getDepartments]);

    return (
        <Box
            bgcolor='background.paper'
            height='calc(100vh - 76px)'
            overflow='auto'
            display='flex'
            flexDirection='column'
            p={0}>
            <Typography variant='h5' fontWeight={600} color='primary' p={2}>
                {goalId ? 'Edit Goal' : 'New Goal'}
            </Typography>
            <Divider variant='fullWidth' />
            {loaderState ? (
                <Loading message='Please wait, while your credentials are loading...' />
            ) : (
                <Form
                    handlers={handlers}
                    onSubmit={onSubmit}
                    action={goalId ? `/goals/${goalId}` : '/goals/'}
                    method={goalId ? 'patch' : 'post'}
                    axiosInstance={server}
                    onError={errorHandler}
                    style={{ flexGrow: 1, position: 'relative' }}>
                    <Box p={2} display='flex' flexDirection='column' mb={4} overflow='auto'>
                        <Grid container columnSpacing={5} rowSpacing={1} alignItems='center' mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Department
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10} display='flex' spacing={2}>
                                <Select
                                    size='small'
                                    name='department'
                                    sx={{ width: 316 }}
                                    value={handlers.values.department}
                                    onChange={customChangeHandler}>
                                    {departments.map(department => (
                                        <MenuItem key={department.id} value={department.id}>
                                            {department.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={5} rowSpacing={1} alignItems='center' mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Category
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10} display='flex' spacing={2}>
                                <Select
                                    size='small'
                                    name='category'
                                    sx={{ width: 316 }}
                                    value={handlers.values.category}
                                    onChange={customChangeHandler}>
                                    {categories.map(category => (
                                        <MenuItem key={category.id} value={category.id}>
                                            {category.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={5} rowSpacing={1} alignItems='center' mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Goal
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10}>
                                <Input
                                    name='goal'
                                    variant='outlined'
                                    size='small'
                                    sx={{ width: { xs: '100%', sm: '90%', lg: '316px' } }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={5} rowSpacing={1} alignItems='center' mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Description
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10} display='flex' spacing={2}>
                                <Input
                                    name='description'
                                    variant='outlined'
                                    size='small'
                                    sx={{ width: { xs: '100%', sm: '90%', lg: '316px' } }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Stack
                        direction='row'
                        p={2}
                        spacing={2}
                        sx={{
                            boxShadow: '0 -4px 5px -3px rgba(0,0,0,.1);',
                            backgroundColor: 'background.paper',
                            zIndex: 1100,
                            position: 'fixed',
                            bottom: 0,
                            width: {
                                xs: '100%',
                                xm: `calc(100% - 280px)`,
                            },
                        }}>
                        <Submit>
                            {loader => (
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    type='submit'
                                    disabled={Boolean(loader)}
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    endIcon={loader}>
                                    Save
                                </Button>
                            )}
                        </Submit>
                        <Button
                            onClick={() => navigate(-1)}
                            sx={{
                                bgcolor: 'background.default',
                                border: '1px solid',
                                borderColor: 'divider',
                            }}>
                            Cancel
                        </Button>
                    </Stack>
                </Form>
            )}
        </Box>
    );
};

export default NewGoal;
