import { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Container,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Divider,
    Button,
    Grid,
} from '@mui/material';
import DataGrid from '../../components/DataGrid';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useMenu } from '../../hooks/useMenu';
import Image from '../../components/Image';
import SearchBar from '../../components/SearchBar';
import { useNavigate } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import useLoader from '../../hooks/useLoader';
import { server } from '../../utils/axios';
import { useUser } from '../../hooks/Authorize';
import useErrorHandler from '../../hooks/useErrorHandler';
import { DateTime } from 'luxon';
import dayjs from 'dayjs';

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        width: 20,
        valueGetter: params => `${params.row.id}`,
    },
    {
        field: 'name',
        headerName: 'Name',
        sortable: false,
        width: 100,
        renderCell: params => (
            <Typography
                variant='subtitle2'
                fontWeight='600'
                sx={{ color: 'primary.main' }}>
                {params.row.name}
            </Typography>
        ),
    },

    {
        field: 'slot_time_from',
        headerName: 'Slot Time From',
        sortable: false,
        width: 140,
    },
    {
        field: 'slot_time_to',
        headerName: 'Slot Time To',
        sortable: false,
        width: 140,
    },
    {
        field: 'createdAt',
        headerName: 'Created On',
        width: 140,
        sortable: false,
        renderCell: params =>
            new Date(params.row.created_on).toLocaleDateString('en-GB'),
    },

    {
        field: 'remarks',
        headerName: 'Remarks',
        sortable: false,
        width: 100,
    },
];

const Index = () => {
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });
    const [search, setSearch] = useState('');
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const navigate = useNavigate();
    const { start, end, loaderState } = useLoader();
    const errorHandler = useErrorHandler();
    const [dateInterval, setDateInterval] = useState({ from: null, to: null });

    const getClients = useCallback(async () => {
        start();
        setRows([]);

        const params = {
            limit: paginationModel.pageSize,
            offset: (paginationModel.page + 1 - 1) * paginationModel.pageSize,
        };

        if (search) params.search = search;

        if (dateInterval.from && dateInterval.to) {
            params.created_on__gte = dateInterval.from;
            params.created_on__lte = dateInterval.to;
        }

        try {
            const response = await server.get(`/clients/`, {
                params,
            });
            const clients = response.data;

            setRows(clients.results);
            setRowCount(clients.count);
        } catch (e) {
            errorHandler(e);
        } finally {
            end();
        }
    }, [paginationModel, search, dateInterval, start, end, errorHandler]);

    useEffect(() => {
        getClients();
    }, [getClients]);

    return (
        <Container maxWidth='false'>
            <Box pt={3}>
                <Typography variant='h4' fontWeight={500}>
                    Clients
                </Typography>
                <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{ wordSpacing: '2px' }}>
                    A dedicated space for detailed profiles, assessment, and
                    remarks insights.
                </Typography>
                <Divider variant='fullWidth' sx={{ mt: 2, mb: 4 }} />
            </Box>
            <DataGrid
                rows={rows}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                paginationMode='server'
                pageSizeOptions={[5, 10, 20, 50, 100, 1000]}
                autoHeight
                disableRowSelectionOnClick={true}
                onRowClick={data => navigate(`/clients/${data.id}`)}
                loading={loaderState}
                rowCount={rowCount}
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                    toolbar: {
                        dateInterval,
                        setDateInterval,
                        setSearch,
                        search,
                    },
                }}
                checkboxSelection={false}
                components={{
                    Footer: CustomToolbar,
                }}
            />
        </Container>
    );
};

const filterOptions = [
    { name: 'All', value: '' },
    { name: 'Today', value: 'day' },
    { name: 'This month', value: 'month' },
    { name: 'This year', value: 'year' },
];
export function CustomToolbar({
    dateInterval,
    setDateInterval,
    setSearch,
    search,
}) {
    const { anchorEl, openMenu, closeMenu } = useMenu();
    const [selected, setSelected] = useState('');
    const [interval, setInterval] = useState({ from: null, to: null });
    const [customOption, setCustomOption] = useState(false);
    const navigate = useNavigate();
    const user = useUser();

    function getFilterByDate(value) {
        const dt = DateTime.now();

        return setDateInterval({
            from: dt.startOf(value).toFormat('yyyy-LL-dd'),
            to: dt.endOf(value).toFormat('yyyy-LL-dd'),
        });
    }

    const onMenuClick = value => {
        setInterval({ from: null, to: null });
        if (value) getFilterByDate(value);
        else setDateInterval({ from: null, to: null });
        setSelected(value);
        closeMenu();
    };

    return (
        <GridToolbarContainer sx={{ pb: '24px' }}>
            <Grid container alignItems='center' width='100%' spacing={1}>
                <Grid item xs>
                    <SearchBar
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                </Grid>
                {user.is_superuser && (
                    <Grid item xs={12} sm='auto'>
                        <Button
                            variant='contained'
                            startIcon={<Add />}
                            color='secondary'
                            fullWidth
                            onClick={() => navigate('/clients/new')}>
                            New
                        </Button>
                    </Grid>
                )}

                <Grid item xs={12} sm='auto'>
                    <IconButton
                        color='text.tertiary'
                        onClick={e => {
                            setCustomOption(false);
                            openMenu(e);
                        }}>
                        <FilterAltOutlinedIcon />
                    </IconButton>
                    <Menu
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={closeMenu}
                        sx={{
                            '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
                                width: 'min(100%, 212px)',
                                boxShadow:
                                    'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
                                border: '1px solid #00000017',
                                p: 0.5,
                            },
                        }}>
                        {filterOptions.map((item, i) => (
                            <MenuItem
                                key={i}
                                onClick={() => onMenuClick(item.value)}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                <div>{item.name}</div>

                                {selected === item.value && !customOption && (
                                    <Image
                                        name='check.png'
                                        sx={{ height: '14px' }}
                                    />
                                )}
                            </MenuItem>
                        ))}
                        <MenuItem
                            onClick={() => setCustomOption(true)}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}>
                            <div>Custom</div>

                            {customOption ? (
                                <Image
                                    name='check.png'
                                    sx={{ height: '14px' }}
                                />
                            ) : (
                                selected === 'custom' && (
                                    <Image
                                        name='check.png'
                                        sx={{ height: '14px' }}
                                    />
                                )
                            )}
                        </MenuItem>
                        {customOption && (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Divider variant='middle' />
                                <Box px={1}>
                                    <Typography
                                        variant='body2'
                                        color='text.tertiary'
                                        fontWeight={500}
                                        gutterBottom>
                                        From
                                    </Typography>
                                    <DatePicker
                                        slotProps={{
                                            textField: { size: 'small' },
                                        }}
                                        value={dayjs(interval.from)}
                                        onChange={value =>
                                            setInterval({
                                                ...interval,
                                                from: `${value?.$y}-${
                                                    value?.$M + 1
                                                }-${value?.$D}`,
                                            })
                                        }
                                    />
                                    <Typography
                                        variant='body2'
                                        color='text.tertiary'
                                        fontWeight={500}
                                        gutterBottom>
                                        To
                                    </Typography>
                                    <DatePicker
                                        slotProps={{
                                            textField: { size: 'small' },
                                        }}
                                        value={dayjs(interval.to)}
                                        onChange={value =>
                                            setInterval({
                                                ...interval,
                                                to: `${value?.$y}-${
                                                    value?.$M + 1
                                                }-${value?.$D}`,
                                            })
                                        }
                                    />
                                </Box>
                                <Divider variant='middle' />
                                <Box mt={1.5} textAlign='right' px={1}>
                                    <Button
                                        variant='outlined'
                                        size='small'
                                        sx={{ p: '3px 14px', fontSize: '12px' }}
                                        disabled={
                                            !(interval.from && interval.to)
                                        }
                                        onClick={() => {
                                            setSelected('custom');
                                            setDateInterval(interval);
                                            closeMenu();
                                        }}>
                                        Apply
                                    </Button>
                                </Box>
                            </LocalizationProvider>
                        )}
                    </Menu>
                </Grid>
            </Grid>
        </GridToolbarContainer>
    );
}

export default Index;
