import React, { useCallback, useEffect, useState } from 'react';
import { Box, Container, Typography, Grid, Divider, Chip } from '@mui/material';
import DataGrid from '../components/DataGrid';
import { GridToolbarContainer } from '@mui/x-data-grid';
import SearchBar from '../components/SearchBar';
import { useNavigate } from 'react-router-dom';
import useErrorHandler from '../hooks/useErrorHandler';
import useLoader from '../hooks/useLoader';
import { server } from '../utils/axios';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import green from '@mui/material/colors/green';

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        width: 50,
        valueGetter: params => `${params.row.id}`,
    },
    {
        field: 'client',
        headerName: 'Client',
        sortable: false,
        width: 110,
        renderCell: params => (
            <Typography
                variant='subtitle2'
                fontWeight='600'
                sx={{ color: 'primary.main' }}>
                {params.row.client.name}
            </Typography>
        ),
    },
    {
        field: 'date',
        headerName: 'Date',
        sortable: false,
        width: 100,
    },
    {
        field: 'session_count',
        headerName: 'No. of sessions',
        sortable: false,
        width: 140,
        align: 'center',
    },
    {
        field: 'total_time',
        headerName: 'Total duration',
        sortable: false,
        align: 'center',
    },
];

const liveColumns = [
    {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        width: 50,
        valueGetter: params => `${params.row.id}`,
    },
    {
        field: 'client_name',
        headerName: 'Client',
        sortable: false,
        width: 100,
        renderCell: params => (
            <Typography
                variant='subtitle2'
                fontWeight='600'
                sx={{ color: 'primary.main' }}>
                {params.row.client_name}
            </Typography>
        ),
    },
    {
        field: 'client_goal_name',
        headerName: 'Goal',
        sortable: false,
        width: 120,
    },
    {
        field: 'activity_name',
        headerName: 'Activity',
        sortable: false,
        width: 120,
    },
    {
        sortable: false,
        renderCell: () => (
            <Chip
                label='Live'
                color='success'
                variant='outlined'
                size='small'
                icon={
                    <FiberManualRecordIcon
                        sx={{ color: green['A700'] }}
                        fontSize='small'
                    />
                }
            />
        ),
    },
];

const Index = () => {
    const [search, setSearch] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });
    const [livePaginationModel, setLivePaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });
    const [rows, setRows] = useState([]);
    const [liveRows, setLiveRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [liveRowCount, setLiveRowCount] = useState(0);
    const navigate = useNavigate();
    const { start, end, loaderState } = useLoader();
    const {
        start: liveStart,
        end: liveEnd,
        loaderState: liveLoaderState,
    } = useLoader();
    const errorHandler = useErrorHandler();

    const getSessions = useCallback(async () => {
        start();
        setRows([]);

        const params = {
            limit: paginationModel.pageSize,
            offset: (paginationModel.page + 1 - 1) * paginationModel.pageSize,
        };

        if (search) params.search = search;

        try {
            const response = await server.get(`/sessions-by-dates/`, {
                params,
            });
            const clients = response.data;

            const rows = clients.results.map(session => ({
                ...session,
                id: session.client.id,
            }));

            setRows(rows);
            setRowCount(clients.count);
        } catch (e) {
            errorHandler(e);
        } finally {
            end();
        }
    }, [paginationModel, search, start, end, errorHandler]);

    const getLiveSessions = useCallback(async () => {
        liveStart();
        setLiveRows([]);

        const params = {
            limit: livePaginationModel.pageSize,
            offset:
                (livePaginationModel.page + 1 - 1) *
                livePaginationModel.pageSize,
            in_progress: 1,
        };

        try {
            const response = await server.get(`/sessions/`, { params });
            const sessions = response.data;

            setLiveRows(sessions.results);
            setLiveRowCount(sessions.count);
        } catch (e) {
            errorHandler(e);
        } finally {
            liveEnd();
        }
    }, [livePaginationModel, liveStart, liveEnd, errorHandler]);

    useEffect(() => {
        getSessions();
    }, [getSessions]);
    useEffect(() => {
        getLiveSessions();
    }, [getLiveSessions]);

    return (
        <Container maxWidth='false'>
            <Box pt={3}>
                <Typography variant='h4' fontWeight={500}>
                    Session
                </Typography>
                <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{ wordSpacing: '2px' }}>
                    An organized space for scheduling, goals, activity and
                    observation tracking.
                </Typography>
                <Divider variant='fullWidth' sx={{ mt: 2, mb: 4 }} />
            </Box>

            <Grid container spacing={3}>
                <Grid item xs={12} xl={6}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        paginationMode='server'
                        pageSizeOptions={[5, 10, 20, 50, 100, 1000]}
                        autoHeight
                        disableRowSelectionOnClick={true}
                        onRowClick={data =>
                            navigate(`/clients/${data.id}/sessions`)
                        }
                        loading={loaderState}
                        rowCount={rowCount}
                        slots={{ toolbar: CustomToolbar }}
                        slotProps={{
                            toolbar: { setSearch, search },
                        }}
                        checkboxSelection={false}
                        components={{
                            Footer: CustomToolbar,
                        }}
                    />
                </Grid>
                <Grid item xs={12} xl={6}>
                    <Typography
                        variant='h5'
                        color='text.secondary'
                        mb={3.5}
                        mt={1}>
                        Live sessions
                    </Typography>
                    <DataGrid
                        rows={liveRows}
                        columns={liveColumns}
                        paginationModel={livePaginationModel}
                        onPaginationModelChange={setLivePaginationModel}
                        paginationMode='server'
                        pageSizeOptions={[5, 10, 20, 50, 100, 1000]}
                        onRowClick={data =>
                            navigate(
                                `/clients/${data.row.id}/sessions/edit/${data.id}`
                            )
                        }
                        autoHeight
                        disableRowSelectionOnClick={true}
                        loading={liveLoaderState}
                        rowCount={liveRowCount}
                        checkboxSelection={false}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export function CustomToolbar({ setSearch, search }) {
    return (
        <GridToolbarContainer sx={{ pb: '24px' }}>
            <Grid container alignItems='center' width='100%' spacing={1}>
                <Grid item xs>
                    <SearchBar
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                </Grid>
            </Grid>
        </GridToolbarContainer>
    );
}

export default Index;
