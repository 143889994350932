import { Box, Card, CardContent, IconButton, Typography } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';

const DashboardCard = props => {
    const { Title, Number, Icon, Color, url } = props;
    return (
        <>
            <Card>
                <CardContent
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                    <Box>
                        <Typography mb={1}>{Title}</Typography>
                        <Typography variant='h6' sx={{ color: Color }}>
                            {Number}
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton
                            LinkComponent={NavLink}
                            to={`/users/?user=${url}`}
                            sx={{
                                p: 1.5,
                                background: Color,
                                borderRadius: '8px',
                                '&:hover': {
                                    background: Color,
                                },
                            }}>
                            {Icon}
                        </IconButton>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
};

export default DashboardCard;
