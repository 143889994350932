import { MenuItem, TextField, Stack, Divider, Select } from '@mui/material';
import { Box, Button, Grid, Typography } from '@mui/material';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Form, useForm } from '../../../hooks/useForm';
import { Input } from '../../../hooks/useForm/inputs/index';
import { useMessage } from '../../../components/Header';
import Loading from '../../../components/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import useErrorHandler from '../../../hooks/useErrorHandler';
import useLoader from '../../../hooks/useLoader';
import { server } from '../../../utils/axios';
import { DateTime } from 'luxon';
import { SelectWithSearch } from '../../../components/Select';
import { useUser } from '../../../hooks/Authorize';

const startSessionFields = {
    client_goal: { required: true, value: '' },
    activity: { required: true, value: '' },
    start_time: { required: true, value: '' },
};

const endSessionFields = {
    end_time: { required: true, value: '' },
    score: { required: true, value: '' },
    observation: { required: true, value: '' },
};

const NewSession = () => {
    const { sessionId, clientId } = useParams();
    const { showSuccess, showError } = useMessage();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const { start, end, loaderState } = useLoader();
    const [clientGoals, setClientGoals] = useState({});
    const [activities, setActivities] = useState({});
    const [sessionTimer, setSessionTimer] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [selectedGoal, setSelectedGoal] = useState(null);
    const user = useUser();
    const {
        start: startUploading,
        end: endUploading,
        loaderState: uploadingState,
        circular,
    } = useLoader();
    const startTimeRef = useRef(0);
    const fileRef = useRef(null);

    const handlers = useForm(
        useMemo(
            () => (sessionId ? endSessionFields : startSessionFields),
            [sessionId]
        ),
        { Input: TextField }
    );

    const setValues = handlers.setValues;
    const values = handlers.values;

    const getSession = useCallback(async () => {
        start();
        try {
            const response = await server.get(`/sessions/${sessionId}`);
            const { start_time, end_time } = response.data;

            const startTime = DateTime.fromISO(start_time);
            const endTime = DateTime.fromISO(end_time);

            if (!end_time) {
                startTimeRef.current = Date.parse(startTime);
                setIsRunning(true);
                return;
            }

            setSessionTimer(Date.parse(endTime) - Date.parse(startTime));
        } catch (e) {
            errorHandler(e);
        } finally {
            end();
        }
    }, [sessionId, start, end, errorHandler]);

    const getClientGoals = useCallback(
        async search => {
            try {
                const params = {
                    department: user.department,
                    client_id: clientId,
                };

                if (search) params.search = search;

                const response = await server.get(`/client-goals/`, { params });

                setClientGoals({});
                const clientGoals = response.data.results;

                const format = {};

                clientGoals.forEach(goal => (format[goal.id] = goal));

                setClientGoals(format);
            } catch (e) {
                errorHandler(e);
            }
        },
        [clientId, errorHandler, user]
    );

    const getActivities = useCallback(
        async search => {
            try {
                setActivities({});

                console.log({ client_goal: values.client_goal });

                const params = {
                    client_goal: values.client_goal,
                };

                if (search) params.search = search;

                const response = await server.get(`/activities/`, { params });

                const activities = response.data.results;

                const format = {};

                activities.forEach(
                    activity => (format[activity.id] = activity.name)
                );

                setActivities(format);
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler, values.client_goal]
    );

    const customChangeHandler = e => {
        const { name, value } = e.target;
        handlers.setValues({ [name]: value });
    };

    const onSubmit = response => {
        if (response.data) {
            navigate(-1);
            showSuccess('Session saved successfully!');
        }
    };

    const fileHandler = event => {
        const file = event.target.files[0];
        if (!file) return showError('File not found');
        uploadFile(file);
    };

    const uploadFile = async file => {
        startUploading();

        try {
            const formData = new FormData();
            formData.append('video', file);

            await server.patch(`/sessions/${sessionId}`, formData);

            showSuccess('Video uploaded successfully!');
        } catch (e) {
            console.log(e);
            errorHandler(e);
        } finally {
            endUploading();
        }
    };

    function startSession() {
        setIsRunning(true);
        const currentTime = new Date();
        startTimeRef.current = currentTime.getTime();
        let hours = currentTime.getHours();
        let minutes = currentTime.getMinutes();

        setValues({ start_time: `${hours}:${minutes}` });
    }

    function endSession() {
        setIsRunning(false);
        const currentTime = new Date();
        let hours = currentTime.getHours();
        let minutes = currentTime.getMinutes();

        setValues({ end_time: `${hours}:${minutes}` });
    }

    const formattedTime = useMemo(() => {
        let hours = Math.floor(sessionTimer / (1000 * 60 * 60));
        let minutes = Math.floor((sessionTimer / (1000 * 60)) % 60);
        let seconds = Math.floor((sessionTimer / 1000) % 60);
        let miliseconds = Math.floor((sessionTimer % 1000) / 10);

        hours = String(hours).padStart(2, '0');
        minutes = String(minutes).padStart(2, '0');
        seconds = String(seconds).padStart(2, '0');
        miliseconds = String(miliseconds).padStart(2, '0');

        return `${hours}:${minutes}:${seconds}:${miliseconds}`;
    }, [sessionTimer]);

    useEffect(() => {
        if (!isRunning) return;
        const interval = setInterval(
            () => setSessionTimer(Date.now() - startTimeRef.current),
            10
        );

        return () => clearInterval(interval);
    }, [isRunning]);

    useEffect(() => {
        if (sessionId) getSession();
    }, [sessionId, getSession]);

    useEffect(() => {
        getClientGoals();
    }, [getClientGoals]);

    useEffect(() => {
        if (values.client_goal) getActivities();
    }, [getActivities, values.client_goal]);

    return (
        <Box
            bgcolor='background.paper'
            height='calc(100vh - 76px)'
            overflow='auto'
            display='flex'
            flexDirection='column'
            p={0}>
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'>
                <Typography variant='h5' fontWeight={600} color='primary' p={2}>
                    Session
                </Typography>

                <Stack direction='row' spacing={2} alignItems='center'>
                    {!user.is_superuser && (
                        <Button
                            variant='outlined'
                            disabled={uploadingState}
                            endIcon={circular}
                            onClick={() => fileRef.current?.click()}>
                            Upload Video
                        </Button>
                    )}
                    <input
                        type='file'
                        accept='video/*'
                        style={{ display: 'none' }}
                        ref={fileRef}
                        onChange={fileHandler}
                        onClick={() => {
                            fileRef.current.value = '';
                        }}
                    />
                    <Typography variant='h6' color='primary' p={2}>
                        {formattedTime}
                    </Typography>
                </Stack>
            </Stack>
            <Divider variant='fullWidth' />
            {loaderState ? (
                <Loading message='Please wait, while your credentials are loading...' />
            ) : (
                <Form
                    handlers={handlers}
                    onSubmit={onSubmit}
                    action={sessionId ? `/sessions/${sessionId}` : '/sessions/'}
                    method={sessionId ? 'patch' : 'post'}
                    axiosInstance={server}
                    onError={error => {
                        if (error && sessionId) setIsRunning(true);
                        else setIsRunning(false);
                        errorHandler(error);
                    }}
                    style={{ flexGrow: 1, position: 'relative' }}>
                    <Box
                        p={2}
                        display='flex'
                        flexDirection='column'
                        mb={4}
                        overflow='auto'>
                        {!sessionId ? (
                            <>
                                <Grid
                                    container
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems='center'
                                    mb={2}>
                                    <Grid item xs={12} lg>
                                        <Typography
                                            variant='body2'
                                            fontWeight={600}>
                                            Client Goal
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} lg={10} display='flex'>
                                        <SelectWithSearch
                                            displayEmpty
                                            name='client_goal'
                                            onChange={customChangeHandler}
                                            value={handlers.values.client_goal}
                                            renderValue={v => {
                                                if (
                                                    !handlers.values.client_goal
                                                )
                                                    return '';
                                                return clientGoals[v].goal.goal;
                                            }}
                                            sx={{ width: 316 }}
                                            SearchProps={{
                                                onChange: e =>
                                                    getClientGoals(
                                                        e.target.value
                                                    ),
                                            }}>
                                            {Object.keys(clientGoals).map(
                                                goal => (
                                                    <MenuItem
                                                        value={goal}
                                                        key={goal}
                                                        onClick={() =>
                                                            setSelectedGoal(
                                                                clientGoals[
                                                                    goal
                                                                ].goal.id
                                                            )
                                                        }>
                                                        {
                                                            clientGoals[goal]
                                                                .goal.goal
                                                        }
                                                    </MenuItem>
                                                )
                                            )}
                                        </SelectWithSearch>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems='center'
                                    mb={2}>
                                    <Grid item xs={12} lg>
                                        <Typography
                                            variant='body2'
                                            fontWeight={600}>
                                            Activity
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        lg={10}
                                        display='flex'
                                        spacing={2}>
                                        <SelectWithSearch
                                            displayEmpty
                                            value={handlers.values.activity}
                                            name='activity'
                                            disabled={!selectedGoal}
                                            onChange={customChangeHandler}
                                            renderValue={v => {
                                                if (!handlers.values.activity)
                                                    return '';
                                                return activities[v];
                                            }}
                                            sx={{ width: 316 }}
                                            SearchProps={{
                                                onChange: e =>
                                                    getActivities(
                                                        e.target.value
                                                    ),
                                            }}>
                                            {Object.keys(activities).map(
                                                activity => (
                                                    <MenuItem
                                                        value={activity}
                                                        key={activity}>
                                                        {activities[activity]}
                                                    </MenuItem>
                                                )
                                            )}
                                        </SelectWithSearch>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems='center'
                                    mb={2}>
                                    <Grid item xs={12} lg>
                                        <Typography
                                            variant='body2'
                                            fontWeight={600}>
                                            Start
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        lg={10}
                                        display='flex'
                                        spacing={2}>
                                        <Input
                                            name='start_time'
                                            variant='outlined'
                                            type='time'
                                            size='small'
                                            value={handlers.values.start_time}
                                            disabled={true}
                                            sx={{ width: 316 }}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid
                                    container
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems='center'
                                    mb={2}>
                                    <Grid item xs={12} lg>
                                        <Typography
                                            variant='body2'
                                            fontWeight={600}>
                                            End
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        lg={10}
                                        display='flex'
                                        spacing={2}>
                                        <Input
                                            name='end_time'
                                            variant='outlined'
                                            type='time'
                                            size='small'
                                            value={handlers.values.end_time}
                                            disabled={true}
                                            sx={{
                                                maxWidth: 316,
                                                width: '100%',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems='center'
                                    mb={2}>
                                    <Grid item xs={12} lg>
                                        <Typography
                                            variant='body2'
                                            fontWeight={600}>
                                            Score
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} lg={10}>
                                        {/* <Input
                                            name='score'
                                            variant='outlined'
                                            type='number'
                                            size='small'
                                            sx={{
                                                maxWidth: 316,
                                                width: '100%',
                                            }}
                                        /> */}
                                        <Select
                                            name='score'
                                            sx={{
                                                maxWidth: 316,
                                                width: '100%',
                                            }}
                                            onChange={e =>
                                                handlers.setValues({
                                                    score: e.target.value,
                                                })
                                            }>
                                            <MenuItem value='1'>1</MenuItem>
                                            <MenuItem value='2'>2</MenuItem>
                                            <MenuItem value='3'>3</MenuItem>
                                            <MenuItem value='4'>4</MenuItem>
                                            <MenuItem value='5'>5</MenuItem>
                                            <MenuItem value='6'>6</MenuItem>
                                            <MenuItem value='7'>7</MenuItem>
                                            <MenuItem value='8'>8</MenuItem>
                                            <MenuItem value='9'>9</MenuItem>
                                            <MenuItem value='10'>10</MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems='center'
                                    mb={2}>
                                    <Grid item xs={12} lg>
                                        <Typography
                                            variant='body2'
                                            fontWeight={600}>
                                            Observation
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} lg={10}>
                                        <Input
                                            name='observation'
                                            variant='outlined'
                                            size='small'
                                            sx={{
                                                maxWidth: 316,
                                                width: '100%',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Box>
                    <Stack
                        direction='row'
                        p={2}
                        spacing={2}
                        sx={{
                            boxShadow: '0 -4px 5px -3px rgba(0,0,0,.1);',
                            backgroundColor: 'background.paper',
                            zIndex: 1100,
                            position: 'fixed',
                            bottom: 0,
                            width: {
                                xs: '100%',
                                xm: `calc(100% - 280px)`,
                            },
                        }}>
                        {/* <Submit>
                            {loader => (
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    type='submit'
                                    disabled={Boolean(loader)}
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    onClick={() => setIsRunning(false)}
                                    endIcon={loader}>
                                    Save
                                </Button>
                            )}
                        </Submit> */}
                        <Button
                            type='submit'
                            variant='contained'
                            color='secondary'
                            sx={{ mr: 1 }}
                            disabled={
                                !(
                                    values.client_goal &&
                                    values.activity &&
                                    !sessionId
                                )
                            }
                            onClick={startSession}>
                            Start Session
                        </Button>
                        <Button
                            variant='contained'
                            color='secondary'
                            type='submit'
                            sx={{ mr: 1 }}
                            disabled={
                                !(
                                    values.score?.trim() &&
                                    values.observation?.trim()
                                )
                            }
                            onClick={endSession}>
                            End Session
                        </Button>
                        <Button
                            onClick={() => navigate(-1)}
                            sx={{
                                bgcolor: 'background.default',
                                border: '1px solid',
                                borderColor: 'divider',
                            }}>
                            Cancel
                        </Button>
                    </Stack>
                </Form>
            )}
        </Box>
    );
};

export default NewSession;
