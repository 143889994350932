import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import ClientTable from './ClientTable';

const UserDashboard = () => {
    return (
        <Grid container spacing={4} mt={2}>
            <Grid item xs={12} lg={12} xl={12}>
                <Card elevation={0} sx={{ height: '100%' }}>
                    <CardContent>
                        <Box
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            mb={2}>
                            <Typography variant='h6'>
                                Sessions Completed by Client
                            </Typography>
                        </Box>
                        <ClientTable />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default UserDashboard;
