import {
    Box,
    Button,
    Card,
    Chip,
    Divider,
    Grid,
    Stack,
    Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import useErrorHandler from '../../../../../hooks/useErrorHandler';
import { useCallback, useEffect, useState } from 'react';
import useLoader from '../../../../../hooks/useLoader';
import { server } from '../../../../../utils/axios';
import DataGrid from '../../../../../components/DataGrid';
import Add from '@mui/icons-material/Add';
import { useUser } from '../../../../../hooks/Authorize';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import green from '@mui/material/colors/green';

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        width: 50,
        valueGetter: params => `${params.row.id}`,
    },
    {
        field: 'department.name',
        headerName: 'Department',
        sortable: false,
        width: 120,
        renderCell: params => (
            <Typography
                variant='subtitle2'
                fontWeight='600'
                sx={{ color: 'primary.main' }}>
                {params.row.department.name}
            </Typography>
        ),
    },

    {
        field: 'session_count',
        headerName: 'No. of sessions',
        width: 140,
    },
    {
        headerName: 'Total duration',
        width: 150,
        renderCell: params =>
            params.row.total_time ? params.row.total_time : 'N/A',
    },
];

const Index = () => {
    const { clientId } = useParams();
    const [rows, setRows] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });
    const [rowCount, setRowCount] = useState(0);
    const [liveSessions, setLiveSessions] = useState([]);
    const { start, end, loaderState } = useLoader();
    const errorHandler = useErrorHandler();
    const navigate = useNavigate();
    const user = useUser();

    const getLiveSessions = useCallback(async () => {
        const params = {
            client_id: clientId,
            in_progress: 1,
        };

        try {
            const response = await server.get(`/sessions/`, { params });
            const sessions = response.data.results;

            setLiveSessions(sessions);
        } catch (e) {
            errorHandler(e);
        }
    }, [clientId, errorHandler]);

    const getSessions = useCallback(async () => {
        start();
        setRows([]);

        const params = {
            limit: paginationModel.pageSize,
            offset: (paginationModel.page + 1 - 1) * paginationModel.pageSize,
        };

        try {
            const response = await server.get(
                `/sessions-by-clients/${clientId}`,
                { params }
            );
            const sessions = response.data;

            setRows(
                sessions.results.map(session => ({
                    ...session,
                    id: session.department.id,
                }))
            );
            setRowCount(sessions.count);
        } catch (e) {
            errorHandler(e);
        } finally {
            end();
        }
    }, [clientId, paginationModel, start, end, errorHandler]);

    useEffect(() => {
        getSessions();
    }, [getSessions]);

    useEffect(() => {
        getLiveSessions();
    }, [getLiveSessions]);

    return (
        <Box p={2}>
            {Boolean(liveSessions.length) && (
                <>
                    <Stack
                        direction='row'
                        mb={2}
                        justifyContent='space-between'
                        alignItems='center'>
                        <Typography variant='h6'> Live sessions</Typography>
                    </Stack>

                    <Divider variant='fullWidth' sx={{ mb: 2 }} />
                    <Grid container spacing={3} alignItems='center' mb={4}>
                        {liveSessions.map(live => (
                            <Grid item xs={12} md={6} xm={4} lg={3}>
                                <Card
                                    sx={{ p: 2, cursor: 'pointer' }}
                                    onClick={() => navigate(`edit/${live.id}`)}>
                                    <Stack
                                        direction='row'
                                        alignItems='center'
                                        justifyContent='space-between'
                                        mb={3}>
                                        <Typography
                                            variant='h6'
                                            color='primary'>
                                            {live.client_goal_name}
                                        </Typography>
                                        <Chip
                                            label='Live'
                                            color='success'
                                            variant='outlined'
                                            size='small'
                                            icon={
                                                <FiberManualRecordIcon
                                                    sx={{
                                                        color: green['A700'],
                                                    }}
                                                    fontSize='small'
                                                />
                                            }
                                        />
                                    </Stack>
                                    <Stack
                                        direction='row'
                                        alignItems='center'
                                        justifyContent='space-between'
                                        mb={1}>
                                        <Box>
                                            <Typography
                                                variant='body2'
                                                fontWeight={600}
                                                textTransform='uppercase'>
                                                Client
                                            </Typography>
                                            <Typography
                                                variant='caption'
                                                color='text.secondary'>
                                                {live.client_name}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                variant='body2'
                                                fontWeight={600}
                                                textTransform='uppercase'>
                                                Activity
                                            </Typography>
                                            <Typography
                                                variant='caption'
                                                color='text.secondary'>
                                                {live.activity_name}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                variant='body2'
                                                fontWeight={600}
                                                textTransform='uppercase'>
                                                Start on
                                            </Typography>
                                            <Typography
                                                variant='caption'
                                                color='text.secondary'>
                                                {live.start_time}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
            <Stack
                direction='row'
                mb={2}
                justifyContent='space-between'
                alignItems='center'>
                <Typography variant='h6'>Sessions</Typography>
                {!user.is_superuser && (
                    <Button
                        variant='contained'
                        color='secondary'
                        sx={{ borderRadius: '6px' }}
                        startIcon={<Add />}
                        onClick={() => navigate('new')}>
                        New
                    </Button>
                )}
            </Stack>

            <Divider variant='fullWidth' sx={{ mb: 2 }} />

            <DataGrid
                rows={rows}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                onRowClick={params => navigate(`department/${params.id}`)}
                paginationMode='server'
                pageSizeOptions={[5, 10, 20, 50, 100, 1000]}
                autoHeight
                disableSelectionOnClick={true}
                loading={loaderState}
                rowCount={rowCount}
                checkboxSelection={false}
            />
        </Box>
    );
};

export default Index;
