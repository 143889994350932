import { useCallback, useState } from 'react';

export default function useLoadedState() {
    const [state, setState] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const load = useCallback(
        async fn => {
            setLoaded(false);
            const v = await fn();

            if (!Array.isArray(v)) {
                const err = new Error('Load should only return an array');
                throw err;
            }

            setLoaded(true);
            setState(v);
        },
        [setLoaded, setState]
    );

    return { state, load, loaded };
}
