import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Container,
    Typography,
    Divider,
    Button,
    Grid,
    Menu,
    Stack,
} from '@mui/material';
import DataGrid from '../../../components/DataGrid';
import { GridActionsCellItem, GridToolbarContainer } from '@mui/x-data-grid';
import SearchBar from '../../../components/SearchBar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useErrorHandler from '../../../hooks/useErrorHandler';
import useLoader from '../../../hooks/useLoader';
import { server } from '../../../utils/axios';
import { useMenu } from '../../../hooks/useMenu';

const Index = () => {
    const [rows, setRows] = useState([]);
    const [searchParams] = useSearchParams();
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });
    const [rowCount, setRowCount] = useState(0);
    const { start, end, loaderState } = useLoader();
    const {
        start: deleteStart,
        end: deleteEnd,
        loaderState: deleting,
        circular,
    } = useLoader();
    const errorHandler = useErrorHandler();
    const navigate = useNavigate();
    const { anchorEl, openMenu, closeMenu, payload = {} } = useMenu();

    const getUsers = useCallback(async () => {
        start();
        setRows([]);

        const params = {
            limit: paginationModel.pageSize,
            offset: (paginationModel.page + 1 - 1) * paginationModel.pageSize,
            user: searchParams.get('user'),
        };

        try {
            const response = await server.get(`/user/`, { params });
            const users = response.data;

            setRows(users.results);
            setRowCount(users.count);
        } catch (e) {
            errorHandler(e);
        } finally {
            end();
        }
    }, [paginationModel, start, end, errorHandler, searchParams]);

    const deleteUser = useCallback(async () => {
        deleteStart();
        try {
            await server.delete(`/user/${payload.id}/`);
        } catch (e) {
            errorHandler(e);
        } finally {
            deleteEnd();
            closeMenu();
            getUsers();
        }
    }, [errorHandler, getUsers, payload.id, deleteStart, deleteEnd, closeMenu]);

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    const columns = useMemo(
        () => [
            {
                field: 'id',
                headerName: 'ID',
                sortable: false,
                width: 50,
                valueGetter: params => `${params.row.id + 1}`,
            },
            {
                field: 'username',
                headerName: 'Username',
                sortable: false,
                width: 100,
                renderCell: params => (
                    <Typography
                        variant='subtitle2'
                        fontWeight='600'
                        sx={{ color: 'primary.main' }}>
                        {params.row.username}
                    </Typography>
                ),
            },
            {
                field: 'email',
                headerName: 'Email Address',
                width: 150,
            },
            {
                field: 'department_name',
                headerName: 'DPT',
                width: 50,
                renderCell: params => params.row.department_name || 'N/A',
            },
            {
                field: 'designation',
                headerName: 'Designation',
                width: 140,
                renderCell: params => params.row.designation || 'N/A',
            },
            {
                field: 'actions',
                type: 'actions',
                width: 100,
                getActions: params => [
                    <GridActionsCellItem
                        icon={<EditIcon fontSize='small' color='primary' />}
                        onClick={() => navigate(`${params.row.id}`)}
                        label='Edit'
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon fontSize='small' color='primary' />}
                        onClick={e => openMenu(e, params.row)}
                        label='Delete'
                    />,
                ],
            },
        ],
        [navigate, openMenu]
    );

    console.log(payload);

    return (
        <Container maxWidth='false'>
            <Box pt={3}>
                <Typography variant='h4' fontWeight={500}>
                    Users
                </Typography>
                <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{ wordSpacing: '2px' }}>
                    A dedicated space for detailed Users insights.
                </Typography>
                <Divider variant='fullWidth' sx={{ mt: 2, mb: 4 }} />
            </Box>
            <DataGrid
                rows={rows}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                paginationMode='server'
                pageSizeOptions={[5, 10, 20, 50, 100, 1000]}
                autoHeight
                disableRowSelectionOnClick={true}
                loading={loaderState}
                rowCount={rowCount}
                slots={{ toolbar: CustomToolbar }}
                checkboxSelection={false}
                components={{
                    Footer: CustomToolbar,
                }}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                disablePadding>
                <Box p={2}>
                    <Typography variant='subtitle2' mt={1} fontWeight={500}>
                        Are you sure you want to delete{' '}
                        <b>{payload.first_name + ' ' + payload.last_name}</b>{' '}
                        User?
                    </Typography>
                    <Stack
                        direction='row'
                        pt={4}
                        gap={2}
                        justifyContent='flex-end'>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={deleteUser}
                            disabled={deleting}
                            endIcon={circular}>
                            Delete
                        </Button>

                        <Button
                            onClick={() => closeMenu()}
                            sx={{
                                bgcolor: 'background.default',
                                border: '1px solid',
                                borderColor: 'divider',
                            }}>
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Menu>
        </Container>
    );
};

export function CustomToolbar() {
    const navigate = useNavigate();

    return (
        <GridToolbarContainer sx={{ pb: '24px' }}>
            <Grid container alignItems='center' width='100%' spacing={1}>
                <Grid item xs>
                    <SearchBar />
                </Grid>

                <Grid item xs={12} sm='auto'>
                    <Button
                        variant='contained'
                        startIcon={<Add />}
                        color='secondary'
                        fullWidth
                        onClick={() => navigate('new')}>
                        New
                    </Button>
                </Grid>
            </Grid>
        </GridToolbarContainer>
    );
}

export default Index;
