import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Container,
    Typography,
    Menu,
    Divider,
    Button,
    Grid,
    Stack,
} from '@mui/material';
import DataGrid from '../../../components/DataGrid';
import { GridActionsCellItem, GridToolbarContainer } from '@mui/x-data-grid';
import { useMenu } from '../../../hooks/useMenu';
import SearchBar from '../../../components/SearchBar';
import { useNavigate } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useLoader from '../../../hooks/useLoader';
import useErrorHandler from '../../../hooks/useErrorHandler';
import { server } from '../../../utils/axios';

const Index = () => {
    const [rows, setRows] = useState([]);
    const [search, setSearch] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });
    const [rowCount, setRowCount] = useState(0);
    const { start, end, loaderState } = useLoader();
    const {
        start: deleteStart,
        end: deleteEnd,
        loaderState: deleting,
        circular,
    } = useLoader();
    const errorHandler = useErrorHandler();
    const { anchorEl, openMenu, closeMenu, payload = {} } = useMenu();
    const navigate = useNavigate();

    const getDepartments = useCallback(async () => {
        start();
        setRows([]);

        const params = {
            limit: paginationModel.pageSize,
            offset: (paginationModel.page + 1 - 1) * paginationModel.pageSize,
        };

        if (search) params.search = search;

        try {
            const response = await server.get(`/departments/`, { params });
            const departments = response.data;

            setRows(departments.results);
            setRowCount(departments.count);
        } catch (e) {
            errorHandler(e);
        } finally {
            end();
        }
    }, [paginationModel, search, start, end, errorHandler]);

    const deleteDepartment = useCallback(async () => {
        deleteStart();
        try {
            await server.delete(`/departments/${payload.id}`);
        } catch (e) {
            errorHandler(e);
        } finally {
            deleteEnd();
            closeMenu();
            getDepartments();
        }
    }, [
        errorHandler,
        getDepartments,
        payload.id,
        deleteStart,
        deleteEnd,
        closeMenu,
    ]);

    useEffect(() => {
        getDepartments();
    }, [getDepartments]);

    const columns = useMemo(
        () => [
            {
                field: 'id',
                headerName: 'ID',
                sortable: false,
                width: 50,
            },
            {
                field: 'department',
                headerName: 'Department',
                sortable: false,
                width: 150,
                renderCell: params => (
                    <Typography
                        variant='subtitle2'
                        fontWeight='600'
                        sx={{ color: 'primary.main' }}>
                        {params.row.name}
                    </Typography>
                ),
            },
            {
                field: 'actions',
                type: 'actions',
                width: 100,
                getActions: params => [
                    <GridActionsCellItem
                        icon={<EditIcon fontSize='small' color='primary' />}
                        onClick={() => navigate(`${params.row.id}`)}
                        label='Edit'
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon fontSize='small' color='primary' />}
                        onClick={e => openMenu(e, params.row)}
                        label='Delete'
                    />,
                ],
            },
        ],
        [navigate, openMenu]
    );

    return (
        <Container maxWidth='false'>
            <Box pt={3}>
                <Typography variant='h4' fontWeight={500}>
                    Departments
                </Typography>
                <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{ wordSpacing: '2px' }}>
                    A dedicated space for detailed Departments insights.
                </Typography>
                <Divider variant='fullWidth' sx={{ mt: 2, mb: 4 }} />
            </Box>
            <DataGrid
                rows={rows}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                paginationMode='server'
                pageSizeOptions={[5, 10, 50, 100, 1000]}
                autoHeight
                disableRowSelectionOnClick={true}
                loading={loaderState}
                rowCount={rowCount}
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                    toolbar: { setSearch, search },
                }}
                checkboxSelection={false}
                components={{
                    Footer: CustomToolbar,
                }}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                disablePadding>
                <Box p={2}>
                    <Typography variant='subtitle2' mt={1} fontWeight={500}>
                        Are you sure you want to delete <b>{payload.name}</b>{' '}
                        Department?
                    </Typography>
                    <Stack
                        direction='row'
                        pt={4}
                        gap={2}
                        justifyContent='flex-end'>
                        <Button
                            variant='contained'
                            color='secondary'
                            disabled={deleting}
                            endIcon={circular}
                            onClick={deleteDepartment}>
                            Delete
                        </Button>

                        <Button
                            onClick={() => closeMenu()}
                            sx={{
                                bgcolor: 'background.default',
                                border: '1px solid',
                                borderColor: 'divider',
                            }}>
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Menu>
        </Container>
    );
};

export function CustomToolbar({ setSearch, search }) {
    const navigate = useNavigate();

    return (
        <GridToolbarContainer sx={{ pb: '24px' }}>
            <Grid container alignItems='center' width='100%' spacing={1}>
                <Grid item xs>
                    <SearchBar
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} sm='auto'>
                    <Button
                        variant='contained'
                        startIcon={<Add />}
                        color='secondary'
                        fullWidth
                        onClick={() => navigate('new')}>
                        New
                    </Button>
                </Grid>
            </Grid>
        </GridToolbarContainer>
    );
}

export default Index;
