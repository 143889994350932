import {
    TextField,
    Stack,
    Divider,
    Modal,
    Card,
    MenuItem,
    Select,
    Checkbox,
    TableRow,
    TableHead,
    Table,
    TableBody,
    TableCell,
} from '@mui/material';
import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Submit, useForm } from '../../../hooks/useForm';
import { useMessage } from '../../../components/Header';
import Loading from '../../../components/Loading';
import useLoader from '../../../hooks/useLoader';
import useModal from '../../../hooks/useModal';
import useErrorHandler from '../../../hooks/useErrorHandler';
import { server } from '../../../utils/axios';
import Add from '@mui/icons-material/Add';
import DeleteOutline from '@mui/icons-material/DeleteOutline';

const fieldProps = { name: '', datatype: '', required: false };

const Index = () => {
    const { showSuccess } = useMessage();
    const { loaderState } = useLoader();
    const errorHandler = useErrorHandler();
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [extraFields, setExtraFields] = useState([]);
    const [departmentId, setDepartmentId] = useState(null);
    const [field, setField] = useState(fieldProps);
    const { openModal, closeModal, modalState } = useModal();

    const handlers = useForm(
        useMemo(() => ({}), []),
        { Input: TextField }
    );

    const fetchDepartments = useCallback(async () => {
        try {
            const response = await server.get('/departments/');
            setDepartments(response.data.results);
        } catch (error) {
            errorHandler(error);
        }
    }, [errorHandler]);

    const handleDepartmentChange = e => {
        const deptId = e.target.value;
        setSelectedDepartment(deptId);
        setDepartmentId(deptId);
        const department = departments.find(dept => dept.id === deptId);
        if (department) {
            setExtraFields(department.assessment_fields || []);
        }
    };

    const addField = () => {
        closeModal();
        setExtraFields(prev => [...prev, field]);
        setField(fieldProps);
    };

    const deleteField = i => {
        const updatedFields = [...extraFields];
        updatedFields.splice(i, 1);
        setExtraFields(updatedFields);
    };

    const onSubmit = response => {
        if (response.data) {
            showSuccess('Department saved successfully');
        }
        fetchDepartments();
    };

    useEffect(() => {
        fetchDepartments();
    }, [fetchDepartments]);

    return (
        <Box
            bgcolor='background.paper'
            height='calc(100vh - 76px)'
            overflow='auto'
            display='flex'
            flexDirection='column'
            p={0}>
            <Typography variant='h5' fontWeight={600} color='primary' p={2}>
                {`Create New Form`}
            </Typography>
            <Divider variant='fullWidth' />
            {loaderState ? (
                <Loading message='Please wait, while your credentials are loading...' />
            ) : (
                <Form
                    handlers={handlers}
                    onSubmit={onSubmit}
                    action={
                        departmentId
                            ? `/departments/${departmentId}`
                            : '/departments/'
                    }
                    method={departmentId ? 'patch' : 'post'}
                    axiosInstance={server}
                    onError={errorHandler}
                    final={values => ({
                        ...values,
                        assessment_fields: extraFields,
                    })}
                    style={{ flexGrow: 1, position: 'relative' }}>
                    <Box
                        p={2}
                        display='flex'
                        flexDirection='column'
                        mb={4}
                        overflow='auto'>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Select Department
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={10}>
                                <Select
                                    fullWidth
                                    value={selectedDepartment}
                                    onChange={handleDepartmentChange}
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '90%',
                                            lg: '316px',
                                        },
                                    }}>
                                    {departments.map(dept => (
                                        <MenuItem key={dept.id} value={dept.id}>
                                            {dept.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                        <Divider variant='fullWidth' sx={{ my: 2 }} />
                        {departmentId && (
                            <>
                                <Table size='small'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>FIELD NAME</TableCell>
                                            <TableCell>DATATYPE</TableCell>
                                            <TableCell>REQUIRED</TableCell>
                                            {!Boolean(departmentId) && (
                                                <TableCell>ACTION</TableCell>
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {extraFields.map((field, i) => (
                                            <TableRow key={i}>
                                                <TableCell
                                                    component='th'
                                                    scope='row'>
                                                    {field.name}
                                                </TableCell>
                                                <TableCell>
                                                    {field.datatype}
                                                </TableCell>
                                                <TableCell>
                                                    {field.required
                                                        ? 'Yes'
                                                        : 'No'}
                                                </TableCell>
                                                {!Boolean(departmentId) && (
                                                    <TableCell>
                                                        <DeleteOutline
                                                            fontSize='small'
                                                            onClick={() =>
                                                                deleteField(i)
                                                            }
                                                            sx={{
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <Box textAlign='center' my={2}>
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        startIcon={<Add />}
                                        onClick={openModal}>
                                        Add Field
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Box>
                    <Stack
                        direction='row'
                        p={2}
                        spacing={2}
                        sx={{
                            boxShadow: '0 -4px 5px -3px rgba(0,0,0,.1);',
                            backgroundColor: 'background.paper',
                            zIndex: 1100,
                            position: 'fixed',
                            bottom: 0,
                            width: {
                                xs: '100%',
                                xm: `calc(100% - 280px)`,
                            },
                        }}>
                        <Submit>
                            {loader => (
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    type='submit'
                                    disabled={Boolean(loader)}
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    endIcon={loader}>
                                    Save
                                </Button>
                            )}
                        </Submit>
                    </Stack>
                </Form>
            )}
            <Modal
                open={modalState}
                onClose={closeModal}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <Card
                    sx={{
                        boxShadow: 'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
                        borderRadius: '18px',
                        overflowY: 'auto',
                        maxHeight: '80vh',
                        maxWidth: '600px',
                        width: '100%',
                        mx: 2,
                        '::-webkit-scrollbar': { display: 'none' },
                        p: 2,
                    }}>
                    <Box
                        sx={{
                            p: 3,
                            color: 'text.secondary',
                            fontWeight: 500,
                            letterSpacing: '1.2px',
                            fontSize: '14px',
                        }}>
                        <Typography
                            variant='h5'
                            fontWeight={600}
                            color='primary'
                            mb={2}>
                            Add Field
                        </Typography>
                        <Typography variant='body2' gutterBottom>
                            Data type
                        </Typography>
                        <Select
                            fullWidth
                            name='datatype'
                            value={field.datatype}
                            onChange={e =>
                                setField({ ...field, datatype: e.target.value })
                            }
                            sx={{ mb: 2 }}>
                            <MenuItem value='text'>Text</MenuItem>
                            <MenuItem value='date'>Date</MenuItem>
                            <MenuItem value='number'>Number</MenuItem>
                        </Select>
                        <Typography variant='body2' gutterBottom>
                            Name
                        </Typography>
                        <TextField
                            fullWidth
                            name='name'
                            value={field.name}
                            onChange={e =>
                                setField({ ...field, name: e.target.value })
                            }
                            sx={{ mb: 2 }}
                        />
                        <Stack direction='row' gap={4} alignItems='center'>
                            <Typography variant='body2'>
                                Is required?
                            </Typography>
                            <Checkbox
                                size='small'
                                checked={field.required}
                                onChange={e =>
                                    setField({
                                        ...field,
                                        required: e.target.checked,
                                    })
                                }
                            />
                        </Stack>
                        <Button
                            onClick={addField}
                            sx={{
                                mt: 4,
                                width: '100%',
                                p: 1,
                                fontSize: '16px',
                                color: 'white',
                                bgcolor: 'secondary.main',
                            }}>
                            Add
                        </Button>
                    </Box>
                </Card>
            </Modal>
        </Box>
    );
};

export default Index;
