import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Divider,
    Grid,
    Stack,
    Typography,
} from '@mui/material';
import { Tab, Tabs } from '../../../../styles/tabs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Image from '../../../../components/Image';
import { useNavigate, useParams } from 'react-router-dom';
import useErrorHandler from '../../../../hooks/useErrorHandler';
import { server } from '../../../../utils/axios';
import Loading from '../../../../components/Loading';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { arrayEmpty, isEmpty, falsy } from '../../../../utils/function';
import { useUser } from '../../../../hooks/Authorize';
import useLoadedState from '../../../../hooks/useLoadedState';
import { useReactToPrint } from 'react-to-print';
import AssessmentPDF from './AssessmentPDF';

function TabPanel(props) {
    const { children, value, index } = props;

    return (
        <div role='tabpanel' hidden={value !== index} style={{ height: '90%' }}>
            {value === index && children}
        </div>
    );
}

const Assessment = () => {
    const user = useUser();
    const { clientId } = useParams();
    const [tabSelected, setTabSelected] = useState(0);
    const [selectedAssessment, setSelectedAssessment] = useState({});

    const [expanded, setExpanded] = React.useState(false);

    const handleExpand = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const {
        state: assessments,
        load: loadAssessments,
        loaded: assessmentsLoaded,
    } = useLoadedState();
    const {
        state: draftAssessment,
        load: loadDraftAssessments,
        loaded: draftAssessmentLoaded,
    } = useLoadedState();
    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleChange = (event, newValue) => setTabSelected(newValue);
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();

    const getAssessment = useCallback(async () => {
        loadAssessments(async () => {
            try {
                const response = await server.get(`/assessments/`, {
                    params: {
                        client_id: clientId,
                        expand: 'client,department',
                    },
                });
                return response.data.results;
            } catch (e) {
                errorHandler(e);
            }
            return [];
        });
    }, [errorHandler, clientId, loadAssessments]);

    const getDraftAssessment = useCallback(async () => {
        loadDraftAssessments(async () => {
            try {
                const response = await server.get(`/draft/`, {
                    params: {
                        client_id: clientId,
                        expand: 'client,department',
                    },
                });

                return [response.data];
            } catch (e) {
                if (
                    e.response.status === 400 &&
                    e.response.data.detail === 'not found'
                )
                    return [];

                errorHandler(e);
            }

            return [];
        });
    }, [errorHandler, clientId, loadDraftAssessments]);

    useEffect(() => {
        getAssessment();
    }, [getAssessment]);

    useEffect(() => {
        getDraftAssessment();
    }, [getDraftAssessment]);

    if ([assessmentsLoaded, draftAssessmentLoaded].every(falsy))
        return (
            <Loading message='Please wait, while your assessments are loading...' />
        );

    if ([assessments, draftAssessment].every(arrayEmpty)) {
        return (
            <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                height='100%'
                px={1}>
                <Image name='assessment.svg' sx={{ maxHeight: '350px' }} />
                <Typography variant='h6' gutterBottom>
                    A Comprehensive Assessment
                </Typography>
                <Typography variant='body2' color='text.secondary' mb={2}>
                    A comprehensive tool designed to delve into various facets
                    of your skills, knowledge, and abilities...
                </Typography>

                {!user.is_superuser && (
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={() => navigate('new')}>
                        Create Assessment
                    </Button>
                )}
            </Box>
        );
    }

    return (
        <>
            <Stack
                direction='row'
                p={2}
                justifyContent='space-between'
                alignItems='center'>
                <Typography variant='h6' fontWeight='400'>
                    Assessments
                </Typography>

                {!user.is_superuser && (
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={() => navigate('new')}>
                        Create Assessment
                    </Button>
                )}
            </Stack>
            <Divider variant='fullWidth' sx={{ mb: 2 }} />
            {draftAssessment.map(draftAssessment => (
                <>
                    <Stack
                        direction='row'
                        px={2}
                        py={3}
                        justifyContent='space-between'
                        alignItems='center'>
                        <Typography variant='h6' fontWeight='400'>
                            Draft Assessment
                        </Typography>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={() =>
                                navigate(`draft/${draftAssessment.id}`)
                            }
                            sx={{
                                textTransform: 'capitalize',
                            }}>
                            Open Draft
                        </Button>
                    </Stack>
                    <Divider variant='fullWidth' sx={{ mb: 2 }} />
                </>
            ))}

            {assessments.map(assessment => (
                <Accordion
                    slotProps={{ transition: { unmountOnExit: true } }}
                    expanded={expanded === assessment.id}
                    onChange={handleExpand(assessment.id)}
                    sx={{
                        '&.MuiAccordion-root:before': {
                            backgroundColor: 'transparent',
                        },
                    }}>
                    <AccordionSummary
                        onClick={() =>
                            setSelectedAssessment({
                                ...assessment,
                                name: assessment.client.name,
                                gender: assessment.client.gender,
                                dob: assessment.client.dob,
                            })
                        }
                        expandIcon={<ExpandMoreIcon />}
                        sx={{ p: 1, px: 2, fontSize: '1.25rem' }}>
                        {assessment.department.name} Assessment
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box px={4} py={2}>
                            <Grid
                                container
                                columnSpacing={5}
                                rowSpacing={1}
                                alignItems='center'
                                mb={2}>
                                <Grid item xs={12} sm={3}>
                                    <Typography
                                        variant='body2'
                                        fontWeight={600}>
                                        Client Name
                                    </Typography>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={9}
                                    display='flex'
                                    spacing={2}>
                                    <Typography variant='body2'>
                                        {assessment.client.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                columnSpacing={5}
                                rowSpacing={1}
                                alignItems='center'
                                mb={2}>
                                <Grid item xs={12} sm={3}>
                                    <Typography
                                        variant='body2'
                                        fontWeight={600}>
                                        Date of Birth
                                    </Typography>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={9}
                                    display='flex'
                                    spacing={2}>
                                    <Typography variant='body2'>
                                        {assessment.client.dob}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                columnSpacing={5}
                                rowSpacing={1}
                                alignItems='center'
                                mb={2}>
                                <Grid item xs={12} sm={3}>
                                    <Typography
                                        variant='body2'
                                        fontWeight={600}>
                                        Gender
                                    </Typography>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={9}
                                    display='flex'
                                    spacing={2}>
                                    <Typography variant='body2'>
                                        {assessment.client.gender}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                columnSpacing={5}
                                rowSpacing={1}
                                alignItems='center'
                                mb={2}>
                                <Grid item xs={12} sm={3}>
                                    <Typography
                                        variant='body2'
                                        fontWeight={600}>
                                        Date of Assessment
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={9}>
                                    <Typography variant='body2'>
                                        {assessment.date_of_assessment}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                columnSpacing={5}
                                rowSpacing={1}
                                alignItems='center'
                                mb={2}>
                                <Grid item xs={12} sm={3}>
                                    <Typography
                                        variant='body2'
                                        fontWeight={600}>
                                        Presenting Complaints
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={9}>
                                    <Typography variant='body2'>
                                        {assessment.presenting_complaints}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                columnSpacing={5}
                                rowSpacing={1}
                                alignItems='center'
                                mb={2}>
                                <Grid item xs={12} sm={3}>
                                    <Typography
                                        variant='body2'
                                        fontWeight={600}>
                                        Behavioural Observation
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={9}>
                                    <Typography variant='body2'>
                                        {assessment.behavioural_observation}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                columnSpacing={5}
                                rowSpacing={1}
                                alignItems='center'
                                mb={2}>
                                <Grid item xs={12} sm={3}>
                                    <Typography
                                        variant='body2'
                                        fontWeight={600}>
                                        Test Results
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={9}>
                                    <Typography variant='body2'>
                                        {assessment.test_results}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                columnSpacing={5}
                                rowSpacing={1}
                                alignItems='center'
                                mb={2}>
                                <Grid item xs={12} sm={3}>
                                    <Typography
                                        variant='body2'
                                        fontWeight={600}>
                                        Impression
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={9}>
                                    <Typography variant='body2'>
                                        {assessment.impression}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                columnSpacing={5}
                                rowSpacing={1}
                                alignItems='center'
                                mb={2}>
                                <Grid item xs={12} sm={3}>
                                    <Typography
                                        variant='body2'
                                        fontWeight={600}>
                                        Recommendations
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={9}>
                                    <Typography variant='body2'>
                                        {assessment.recommendations}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Tabs
                                value={tabSelected}
                                onChange={handleChange}
                                sx={{ mt: 4 }}>
                                <Tab label='History' />
                                {Array.isArray(assessment.tests) &&
                                assessment.tests.length ? (
                                    <Tab label='Tests' />
                                ) : null}
                                {isEmpty(assessment.extra_fields) ? null : (
                                    <Tab label='Extra fields' />
                                )}
                            </Tabs>
                            <Box flexGrow={1} py={2} mb={3}>
                                <TabPanel value={tabSelected} index={0}>
                                    <Grid
                                        container
                                        columnSpacing={5}
                                        rowSpacing={1}
                                        alignItems='center'
                                        mb={2}>
                                        <Grid item xs={12} sm={3}>
                                            <Typography
                                                variant='body2'
                                                fontWeight={600}>
                                                Parental History
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={9}>
                                            <Typography variant='body2'>
                                                {assessment.parental_history}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        columnSpacing={5}
                                        rowSpacing={1}
                                        alignItems='center'
                                        mb={2}>
                                        <Grid item xs={12} sm={3}>
                                            <Typography
                                                variant='body2'
                                                fontWeight={600}>
                                                Family History
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            sm={9}
                                            display='flex'
                                            spacing={2}>
                                            <Typography variant='body2'>
                                                {assessment.family_history}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        columnSpacing={5}
                                        rowSpacing={1}
                                        alignItems='center'
                                        mb={2}>
                                        <Grid item xs={12} sm={3}>
                                            <Typography
                                                variant='body2'
                                                fontWeight={600}>
                                                Development History
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            sm={9}
                                            display='flex'
                                            spacing={2}>
                                            <Typography variant='body2'>
                                                {assessment.development_history}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        columnSpacing={5}
                                        rowSpacing={1}
                                        alignItems='center'
                                        mb={2}>
                                        <Grid item xs={12} sm={3}>
                                            <Typography
                                                variant='body2'
                                                fontWeight={600}>
                                                School History
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            sm={9}
                                            display='flex'
                                            spacing={2}>
                                            <Typography variant='body2'>
                                                {assessment.school_history}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </TabPanel>{' '}
                                {Array.isArray(assessment.tests) &&
                                assessment.tests.length ? (
                                    <TabPanel value={tabSelected} index={1}>
                                        {assessment.tests.map((test, i) => (
                                            <React.Fragment key={i}>
                                                <Grid
                                                    container
                                                    columnSpacing={5}
                                                    rowSpacing={1}
                                                    alignItems='center'
                                                    mb={2}>
                                                    <Grid item xs={12} sm={3}>
                                                        <Typography
                                                            variant='body2'
                                                            fontWeight={600}>
                                                            Type
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3}>
                                                        <Typography variant='body2'>
                                                            {test.type}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3}>
                                                        <Typography
                                                            variant='body2'
                                                            fontWeight={600}>
                                                            Score
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3}>
                                                        <Typography variant='body2'>
                                                            {test.quotient}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3}>
                                                        <Typography
                                                            variant='body2'
                                                            fontWeight={600}>
                                                            Months
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3}>
                                                        <Typography variant='body2'>
                                                            {test.months}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Typography
                                                            variant='body2'
                                                            fontWeight={600}>
                                                            Years
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3}>
                                                        <Typography variant='body2'>
                                                            {test.years}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                <Divider
                                                    variant='middle'
                                                    sx={{ mb: 1 }}
                                                />
                                            </React.Fragment>
                                        ))}
                                    </TabPanel>
                                ) : null}
                                {isEmpty(assessment.extra_fields) ? null : (
                                    <TabPanel value={tabSelected} index={2}>
                                        {Object.keys(
                                            assessment.extra_fields
                                        ).map((field, i) => (
                                            <Grid
                                                container
                                                columnSpacing={5}
                                                rowSpacing={1}
                                                key={i}
                                                alignItems='center'
                                                mb={2}>
                                                <Grid item xs={12} sm={3}>
                                                    <Typography
                                                        variant='body2'
                                                        fontWeight={600}>
                                                        {field}
                                                    </Typography>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={9}
                                                    display='flex'
                                                    spacing={2}>
                                                    <Typography variant='body2'>
                                                        {
                                                            assessment
                                                                .extra_fields[
                                                                field
                                                            ]
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </TabPanel>
                                )}
                            </Box>
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={handlePrint}>
                                Print
                            </Button>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            ))}

            <div style={{ display: 'none' }}>
                <AssessmentPDF
                    ref={componentRef}
                    assessment={selectedAssessment}
                />
            </div>
        </>
    );
};

export default Assessment;
