import GroupIcon from '@mui/icons-material/Group';
import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    Menu,
    Typography,
} from '@mui/material';
import { LineChart } from '@mui/x-charts';
import React, { useCallback, useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import DashboardCard from '../../components/DashboardCard';
import { useUser } from '../../hooks/Authorize';
import useErrorHandler from '../../hooks/useErrorHandler';
import { server } from '../../utils/axios';
import ClientTable from './ClientTable';
import PieChart from './PieChart';
import RecentAttendance from './RecentAttendance';
import UserDashboard from './UserDashboard';

const defaultDateRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
};

const Index = () => {
    const errorHandler = useErrorHandler();
    const [metrics, setMetrics] = useState({});
    const [data, setData] = useState([]);
    const user = useUser();
    const [therapistSelectionRange, setTherapistSelectionRange] =
        useState(null);
    const [clientSelectionRange, setClientSelectionRange] = useState(null);
    const [anchorElTherapist, setAnchorElTherapist] = useState(null);
    const [anchorElClient, setAnchorElClient] = useState(null);
    const [tempTherapistRange, setTempTherapistRange] =
        useState(defaultDateRange);
    const [tempClientRange, setTempClientRange] = useState(defaultDateRange);

    const handleTempDateRangeChange = type => ranges => {
        if (type === 'therapist') {
            setTempTherapistRange(ranges.selection);
        } else if (type === 'client') {
            setTempClientRange(ranges.selection);
        }
    };

    const applyDateRange = type => {
        if (type === 'therapist') {
            setTherapistSelectionRange(tempTherapistRange);
            setAnchorElTherapist(null);
        } else if (type === 'client') {
            setClientSelectionRange(tempClientRange);
            setAnchorElClient(null);
        }
    };

    const fetchMetrics = useCallback(async () => {
        try {
            const metrics = await server.get('/metrics/');
            setMetrics(metrics.data);
        } catch (e) {
            errorHandler(e);
        }
    }, [errorHandler]);

    const fetchData = async () => {
        try {
            const response = await server.get('/sessions-by-time/');
            const {
                sessions_by_time: { therapists, clients },
            } = response.data;

            const combinedData = [];

            therapists.forEach(session => {
                combinedData.push({
                    hour: session.completed_hour,
                    Therapists: session.session_count,
                    Clients: 0,
                });
            });

            clients.forEach(session => {
                const existingData = combinedData.find(
                    data => data.hour === session.completed_hour
                );
                if (existingData) {
                    existingData.Clients += session.session_count;
                } else {
                    combinedData.push({
                        hour: session.completed_hour,
                        Therapists: 0,
                        Clients: session.session_count,
                    });
                }
            });

            combinedData.sort((a, b) => a.hour - b.hour);

            setData(combinedData);
        } catch (error) {
            console.error('Error fetching session data:', error);
        }
    };

    useEffect(() => {
        user.is_superuser && fetchMetrics();
    }, [fetchMetrics, user]);

    useEffect(() => {
        user.is_superuser && fetchData();
    }, [user]);

    return (
        <Box px={2}>
            <Box pt={3}>
                <Typography variant='h4' fontWeight={500}>
                    Dashboard
                </Typography>
                <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{ wordSpacing: '2px' }}>
                    Overview: Your Dashboard for Insights and Control
                </Typography>
                <Divider variant='fullWidth' sx={{ mt: 2, mb: 4 }} />
            </Box>
            {user.is_superuser ? (
                <Box my={5}>
                    <Grid container spacing={2}>
                        <Grid item lg={4} md={6} xs={12}>
                            <DashboardCard
                                url='Therapists_in_Sessions'
                                Title='Therapists in Sessions'
                                Number={metrics.Therapists_in_Sessions || 0}
                                Color='#0DCD94'
                                Icon={<GroupIcon sx={{ color: 'white' }} />}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} xs={12}>
                            <DashboardCard
                                url='Therapists_Signed_Out'
                                Title='Therapists Not Signed-in'
                                Number={metrics.Therapists_Signed_Out || 0}
                                Color='#F34932'
                                Icon={<GroupIcon sx={{ color: 'white' }} />}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} xs={12}>
                            <DashboardCard
                                url='Therapists_Idle'
                                Title='Therapists Idle'
                                Number={metrics.Therapists_Idle || 0}
                                Color='#AA4CF2'
                                Icon={<GroupIcon sx={{ color: 'white' }} />}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} lg={8} xl={9}>
                            <Card elevation={0}>
                                <CardContent>
                                    <Typography variant='h6' sx={{ mb: 3 }}>
                                        Overview
                                    </Typography>
                                    <LineChart
                                        height={400}
                                        series={[
                                            {
                                                data: data.length
                                                    ? data.map(
                                                          d => d.Therapists
                                                      )
                                                    : new Array(24).fill(0),
                                                label: 'Therapists',
                                                id: 'Therapists',
                                                color: 'green',
                                            },
                                            {
                                                data: data.length
                                                    ? data.map(d => d.Clients)
                                                    : new Array(24).fill(0),
                                                label: 'Clients',
                                                id: 'Clients',
                                                color: 'blue',
                                            },
                                        ]}
                                        xAxis={[
                                            {
                                                scaleType: 'point',
                                                data: data.length
                                                    ? data.map(
                                                          d => `${d.hour}:00`
                                                      )
                                                    : Array.from(
                                                          { length: 24 },
                                                          (_, i) => `${i}:00`
                                                      ),
                                            },
                                        ]}
                                        yAxis={[
                                            {
                                                min: 0,
                                                max:
                                                    Math.max(
                                                        ...data.map(d =>
                                                            Math.max(
                                                                d.Therapists,
                                                                d.Clients
                                                            )
                                                        ),
                                                        1
                                                    ) + 1,
                                            },
                                        ]}
                                        sx={{
                                            '.MuiLineElement-root, .MuiMarkElement-root':
                                                {
                                                    strokeWidth: 1,
                                                },
                                            '.MuiLineElement-series-Therapists':
                                                {
                                                    stroke: 'green',
                                                },
                                            '.MuiLineElement-series-Clients': {
                                                stroke: 'blue',
                                            },
                                            '.MuiMarkElement-root:not(.MuiMarkElement-highlighted)':
                                                {
                                                    fill: '#fff',
                                                },
                                            '& .MuiMarkElement-highlighted': {
                                                stroke: 'none',
                                            },
                                        }}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} lg={4} xl={3}>
                            <PieChart />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4} mt={2}>
                        <Grid item xs={12} lg={12} xl={12}>
                            <Card elevation={0} sx={{ height: '100%' }}>
                                <CardContent>
                                    <Box
                                        display='flex'
                                        justifyContent='space-between'
                                        alignItems='center'
                                        mb={2}>
                                        <Typography variant='h6'>
                                            Sessions Completed by Therapists
                                        </Typography>
                                        <Box display='flex' alignItems='center'>
                                            <Typography
                                                variant='body2'
                                                color='text.secondary'>
                                                {therapistSelectionRange
                                                    ? `Selected Range: ${therapistSelectionRange.startDate.toLocaleDateString()} - ${therapistSelectionRange.endDate.toLocaleDateString()}`
                                                    : 'No Range Selected'}
                                            </Typography>
                                            <Button
                                                sx={{ ml: 3 }}
                                                variant='outlined'
                                                onClick={event =>
                                                    setAnchorElTherapist(
                                                        event.currentTarget
                                                    )
                                                }>
                                                Select Date Range
                                            </Button>
                                        </Box>
                                        <Menu
                                            anchorEl={anchorElTherapist}
                                            open={Boolean(anchorElTherapist)}
                                            onClose={() =>
                                                setAnchorElTherapist(null)
                                            }>
                                            <Card>
                                                <DateRangePicker
                                                    ranges={[
                                                        tempTherapistRange ||
                                                            defaultDateRange,
                                                    ]}
                                                    onChange={handleTempDateRangeChange(
                                                        'therapist'
                                                    )}
                                                />
                                                <Box
                                                    display='flex'
                                                    justifyContent='flex-end'
                                                    p={1}>
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={() =>
                                                            applyDateRange(
                                                                'therapist'
                                                            )
                                                        }>
                                                        Apply
                                                    </Button>
                                                </Box>
                                            </Card>
                                        </Menu>
                                    </Box>
                                    <RecentAttendance
                                        dateRange={therapistSelectionRange}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                        {/* client table */}
                        <Grid item xs={12} lg={12} xl={12}>
                            <Card elevation={0} sx={{ height: '100%' }}>
                                <CardContent>
                                    <Box
                                        display='flex'
                                        justifyContent='space-between'
                                        alignItems='center'
                                        mb={2}>
                                        <Typography variant='h6'>
                                            Sessions Completed by Client
                                        </Typography>
                                        <Box display='flex' alignItems='center'>
                                            <Typography
                                                variant='body2'
                                                color='text.secondary'>
                                                {clientSelectionRange
                                                    ? `Selected Range: ${clientSelectionRange.startDate.toLocaleDateString()} - ${clientSelectionRange.endDate.toLocaleDateString()}`
                                                    : 'No Range Selected'}
                                            </Typography>
                                            <Button
                                                sx={{ ml: 3 }}
                                                variant='outlined'
                                                onClick={event =>
                                                    setAnchorElClient(
                                                        event.currentTarget
                                                    )
                                                }>
                                                Select Date Range
                                            </Button>
                                        </Box>
                                        <Menu
                                            anchorEl={anchorElClient}
                                            open={Boolean(anchorElClient)}
                                            onClose={() =>
                                                setAnchorElClient(null)
                                            }>
                                            <Card>
                                                <DateRangePicker
                                                    ranges={[
                                                        tempClientRange ||
                                                            defaultDateRange,
                                                    ]}
                                                    onChange={handleTempDateRangeChange(
                                                        'client'
                                                    )}
                                                />
                                                <Box
                                                    display='flex'
                                                    justifyContent='flex-end'
                                                    p={1}>
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={() =>
                                                            applyDateRange(
                                                                'client'
                                                            )
                                                        }>
                                                        Apply
                                                    </Button>
                                                </Box>
                                            </Card>
                                        </Menu>
                                    </Box>
                                    <ClientTable
                                        dateRange={clientSelectionRange}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <UserDashboard />
            )}
        </Box>
    );
};

export default Index;
