import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    Box,
    Container,
    Typography,
    Menu,
    Divider,
    Button,
    Grid,
    Stack,
    Backdrop,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import DataGrid from '../../../components/DataGrid';
import { GridActionsCellItem, GridToolbarContainer } from '@mui/x-data-grid';
import { useMenu } from '../../../hooks/useMenu';
import SearchBar from '../../../components/SearchBar';
import { useNavigate } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useErrorHandler from '../../../hooks/useErrorHandler';
import useLoader from '../../../hooks/useLoader';
import { server } from '../../../utils/axios';
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import Papa from 'papaparse';
import { useMessage } from '../../../components/Header';
import useModal from '../../../hooks/useModal';

const csvFields = ['goal', 'department', 'category', 'description'];

const Index = () => {
    const [rows, setRows] = useState([]);
    const [search, setSearch] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 5,
        page: 0,
    });
    const [rowCount, setRowCount] = useState(0);
    const { start, end, loaderState } = useLoader();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const { anchorEl, openMenu, closeMenu, payload = {} } = useMenu();
    const {
        start: deleteStart,
        end: deleteEnd,
        loaderState: deleting,
        circular,
    } = useLoader();
    const fileRef = useRef(null);
    const { showError } = useMessage();
    const count = useRef(0);
    const [total, setTotal] = useState(0);
    const {
        modalState: backdropState,
        openModal: openBackdrop,
        closeModal: closeBackdrop,
    } = useModal();
    const [selectedDept, setSelectedDept] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [departments, setDepartments] = useState([]);
    const [categories, setCategories] = useState([]);

    const getGoals = useCallback(async () => {
        start();
        setRows([]);

        const params = {
            limit: paginationModel.pageSize,
            offset: (paginationModel.page + 1 - 1) * paginationModel.pageSize,
        };

        if (search) params.search = search;
        if (selectedDept) params.department = selectedDept;
        if (selectedCategory) params.category = selectedCategory;

        try {
            const response = await server.get(`/goals/`, { params });
            const goals = response.data;

            setRows(goals.results);
            setRowCount(goals.count);
        } catch (e) {
            errorHandler(e);
        } finally {
            end();
        }
    }, [
        paginationModel,
        search,
        selectedDept,
        selectedCategory,
        start,
        end,
        errorHandler,
    ]);

    const deleteGoal = useCallback(async () => {
        deleteStart();
        try {
            await server.delete(`/goals/${payload.id}`);
        } catch (e) {
            errorHandler(e);
        } finally {
            deleteEnd();
            closeMenu();
            getGoals();
        }
    }, [errorHandler, getGoals, payload.id, deleteStart, deleteEnd, closeMenu]);

    const createGoal = async (data, categories, departments) => {
        try {
            const categoryId = Number(data.category);
            const departmentId = Number(data.department);

            if (!categoryId) {
                data.category = categories.find(
                    category => category.name === data.category
                )?.id;
            }

            if (!departmentId) {
                data.department = departments.find(
                    department => department.name === data.department
                )?.id;
            }

            await server.post('/goals/', { ...data });
        } catch (e) {
            closeBackdrop();
            errorHandler(e);
        }
    };

    const getGoalCategories = useCallback(async () => {
        const response = await server.get(`/goal-cat/`);
        const categories = response.data;
        setCategories(categories.results);
        return categories.results;
    }, []);

    const getDepartments = useCallback(async () => {
        const params = {
            limit: 1000,
            offset: 0,
        };

        const response = await server.get(`/departments/`, { params });
        const departments = response.data;
        setDepartments(departments.results);
        return departments.results;
    }, []);

    const csvHandler = event => {
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: async function (results) {
                const categories = await getGoalCategories();
                const departments = await getDepartments();

                const header = Object.keys(results.data[0] || []).map(name =>
                    name.toLowerCase()
                );

                const isValidData = csvFields.every(field => {
                    if (header.includes(field)) return true;

                    showError(
                        `${csvFields.join(
                            ', '
                        )} fields are missing in the provided csv file.`
                    );

                    return false;
                });

                if (!isValidData) return;

                setTotal(results.data.length);
                openBackdrop();

                for (let row of results.data) {
                    const data = {};

                    for (let key in row) {
                        const field = key.toLowerCase();
                        if (csvFields.includes(field)) data[field] = row[key];
                    }

                    count.current++;
                    await createGoal(data, categories, departments);
                }

                getGoals();
                closeBackdrop();
            },
        });
    };

    useEffect(() => {
        getGoals();
    }, [getGoals]);

    useEffect(() => {
        getDepartments();
        getGoalCategories();
    }, [getDepartments, getGoalCategories]);

    const columns = useMemo(
        () => [
            {
                field: 'id',
                headerName: 'ID',
                sortable: false,
                width: 50,
                valueGetter: params => `${params.row.id + 1}`,
            },
            {
                field: 'department_name',
                headerName: 'DPT',
                sortable: false,
                width: 50,
            },
            {
                field: 'category_name',
                headerName: 'CAT',
                width: 110,
            },
            {
                field: 'goal',
                headerName: 'Goal',
                width: 250,
                renderCell: params => (
                    <Typography
                        variant='subtitle2'
                        fontWeight='600'
                        sx={{ color: 'primary.main' }}>
                        {params.row.goal}
                    </Typography>
                ),
            },

            {
                field: 'description',
                headerName: 'Description',
                width: 300,
            },

            {
                field: 'actions',
                headerName: 'Action',
                type: 'actions',
                width: 100,
                getActions: params => [
                    <GridActionsCellItem
                        icon={<EditIcon fontSize='small' color='primary' />}
                        onClick={() => navigate(`${params.row.id}`)}
                        label='Edit'
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon fontSize='small' color='primary' />}
                        onClick={e => openMenu(e, params.row)}
                        label='Delete'
                    />,
                ],
            },
        ],
        [navigate, openMenu]
    );

    return (
        <Container maxWidth='false'>
            <Box pt={3}>
                <Typography variant='h4' fontWeight={500}>
                    Goal
                </Typography>
                <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{ wordSpacing: '2px' }}>
                    A dedicated space for detailed Goal insights.
                </Typography>
                <Divider variant='fullWidth' sx={{ mt: 2, mb: 4 }} />
            </Box>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth sx={{ py: 1 }}>
                        <InputLabel>Department</InputLabel>
                        <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={selectedDept}
                            MenuProps={{ disableScrollLock: true }}
                            label='Department'
                            onChange={e => setSelectedDept(e.target.value)}>
                            <MenuItem value=''>All</MenuItem>
                            {departments.map(dept => (
                                <MenuItem key={dept.id} value={dept.id}>
                                    {dept.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth sx={{ py: 1 }}>
                        <InputLabel>Category</InputLabel>
                        <Select
                            value={selectedCategory}
                            label='Category'
                            onChange={e => setSelectedCategory(e.target.value)}>
                            <MenuItem value=''>All</MenuItem>
                            {categories.map(cat => (
                                <MenuItem key={cat.id} value={cat.id}>
                                    {cat.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <DataGrid
                rows={rows}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                paginationMode='server'
                pageSizeOptions={[5, 10, 20, 50, 100, 1000]}
                autoHeight
                disableRowSelectionOnClick={true}
                loading={loaderState}
                rowCount={rowCount}
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                    toolbar: { setSearch, search, fileRef, csvHandler },
                }}
                checkboxSelection={false}
                components={{
                    Footer: CustomToolbar,
                }}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                disablePadding>
                <Box p={2}>
                    <Typography variant='subtitle2' mt={1} fontWeight={500}>
                        Are you sure you want to delete <b>{payload.goal}</b>{' '}
                        Goal?
                    </Typography>
                    <Stack
                        direction='row'
                        pt={4}
                        gap={2}
                        justifyContent='flex-end'>
                        <Button
                            variant='contained'
                            color='secondary'
                            disabled={deleting}
                            endIcon={circular}
                            onClick={deleteGoal}>
                            Delete
                        </Button>

                        <Button
                            onClick={() => closeMenu()}
                            sx={{
                                bgcolor: 'background.default',
                                border: '1px solid',
                                borderColor: 'divider',
                            }}>
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Menu>
            <Backdrop
                sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
                open={backdropState}>
                <Stack direction='row' spacing={2}>
                    <CircularProgress color='inherit' />
                    <Typography variant='h6'>
                        Please wait! Your data is being importing{' '}
                        {count.current} of {total}
                    </Typography>
                </Stack>
            </Backdrop>
        </Container>
    );
};

export function CustomToolbar({ setSearch, search, fileRef, csvHandler }) {
    const navigate = useNavigate();

    return (
        <GridToolbarContainer sx={{ pb: '24px' }}>
            <Grid container alignItems='center' width='100%' spacing={1}>
                <Grid item xs>
                    <SearchBar
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} sm='auto'>
                    <Button
                        variant='contained'
                        startIcon={<VerticalAlignBottomOutlinedIcon />}
                        color='secondary'
                        sx={{ mr: 1 }}
                        onClick={() => fileRef?.current?.click()}>
                        import CSV
                    </Button>
                    <Button
                        variant='contained'
                        startIcon={<Add />}
                        color='secondary'
                        onClick={() => navigate('new')}>
                        New
                    </Button>
                </Grid>
                <input
                    type='file'
                    name='file'
                    accept='.csv'
                    ref={fileRef}
                    onChange={csvHandler}
                    onClick={() => {
                        fileRef.current.value = '';
                    }}
                    style={{ display: 'none' }}
                />
            </Grid>
        </GridToolbarContainer>
    );
}

export default Index;
