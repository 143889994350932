import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Container, Typography, Divider } from '@mui/material';
import DataGrid from '../../../components/DataGrid';
import { GridToolbarContainer } from '@mui/x-data-grid';
import SearchBar from '../../../components/SearchBar';
import useErrorHandler from '../../../hooks/useErrorHandler';
import useLoader from '../../../hooks/useLoader';
import { server } from '../../../utils/axios';
import { useParams } from 'react-router-dom';

const Activity = () => {
    const { goalId } = useParams();
    const [rows, setRows] = useState([]);
    const [search, setSearch] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 5,
        page: 0,
    });
    const [rowCount, setRowCount] = useState(0);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [goalActivity, setGoalActivity] = useState([]);
    const { start, end, loaderState } = useLoader();
    const errorHandler = useErrorHandler();

    const getActivities = useCallback(async () => {
        start();
        setRows([]);

        const params = {
            limit: paginationModel.pageSize,
            offset: (paginationModel.page + 1 - 1) * paginationModel.pageSize,
        };

        if (search) params.search = search;

        try {
            const response = await server.get(`/activities/`, { params });
            const activities = response.data;

            setRows(activities.results);
            setRowCount(activities.count);
        } catch (e) {
            errorHandler(e);
        } finally {
            end();
        }
    }, [paginationModel, search, start, end, errorHandler]);

    const getActivity = useCallback(
        async goal => {
            const params = {
                limit: paginationModel.pageSize,
                offset:
                    (paginationModel.page + 1 - 1) * paginationModel.pageSize,
                goal,
            };

            try {
                const response = await server.get(`/activities/`, {
                    params,
                });

                const activityIds = response.data.results.map(
                    result => result.id
                );

                setGoalActivity(activityIds);

                setRowSelectionModel(activityIds);
            } catch (err) {
                errorHandler(err);
            }
        },
        [errorHandler, paginationModel]
    );

    const addActivity = useCallback(
        async ids => {
            try {
                await server.post(
                    `/goals/assign/${goalId}`,
                    { activities: ids },
                    { params: { mode: 'add' } }
                );
                getActivity(goalId);
            } catch (err) {
                errorHandler(err);
            }
        },
        [getActivity, errorHandler, goalId]
    );

    const removeActivity = useCallback(
        async ids => {
            try {
                await server.post(
                    `/goals/assign/${goalId}`,
                    { activities: ids },
                    { params: { mode: 'remove' } }
                );
                getActivity(goalId);
            } catch (err) {
                errorHandler(err);
            }
        },
        [getActivity, errorHandler, goalId]
    );

    useEffect(() => {
        getActivities();
    }, [getActivities]);

    useEffect(() => {
        if (goalId) getActivity(goalId);
    }, [getActivity, goalId]);

    const columns = useMemo(
        () => [
            {
                field: 'id',
                headerName: 'ID',
                sortable: false,
                width: 100,
            },
            {
                field: 'name',
                headerName: 'Activity',
                sortable: false,
                width: 200,
                renderCell: params => (
                    <Typography
                        variant='subtitle2'
                        fontWeight='600'
                        sx={{ color: 'primary.main' }}>
                        {params.row.name}
                    </Typography>
                ),
            },

            {
                field: 'description',
                headerName: 'Description',
                width: 500,
            },
        ],
        []
    );

    return (
        <Container maxWidth='false'>
            <Box pt={3}>
                <Typography variant='h4' fontWeight={500}>
                    Activity
                </Typography>
                <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{ wordSpacing: '2px' }}>
                    A dedicated space for detailed Activity insights.
                </Typography>
                <Divider variant='fullWidth' sx={{ mt: 2, mb: 4 }} />
            </Box>
            <DataGrid
                rows={rows}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                paginationMode='server'
                pageSizeOptions={[5, 10, 50, 100, 1000]}
                autoHeight
                loading={loaderState}
                rowCount={rowCount}
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                    toolbar: { setSearch, search },
                }}
                checkboxSelection={true}
                disableMultipleRowSelection={true}
                keepNonExistentRowsSelected
                onRowSelectionModelChange={ids => {
                    // if (!ids.length) return setRowSelectionModel(ids);

                    console.log(ids);

                    if (goalActivity.length < ids.length) {
                        addActivity(ids);
                    } else if (goalActivity.length > ids.length) {
                        const remove = goalActivity.filter(
                            activity => !ids.includes(activity)
                        );
                        removeActivity(remove);
                    }

                    setRowSelectionModel(ids);
                }}
                rowSelectionModel={rowSelectionModel}
                components={{
                    Footer: CustomToolbar,
                }}
            />
        </Container>
    );
};

export function CustomToolbar({ setSearch, search }) {
    return (
        <GridToolbarContainer sx={{ pb: '24px' }}>
            <SearchBar
                value={search}
                onChange={e => setSearch(e.target.value)}
            />
        </GridToolbarContainer>
    );
}

export default Activity;
