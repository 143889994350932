import { MenuItem, Select, TextField, Stack, Divider } from '@mui/material';
import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Submit, useForm } from '../../../hooks/useForm';
import { Input } from '../../../hooks/useForm/inputs/index';
import { useMessage } from '../../../components/Header';
import Loading from '../../../components/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import useErrorHandler from '../../../hooks/useErrorHandler';
import { server } from '../../../utils/axios';
import useLoader from '../../../hooks/useLoader';
import { useUser } from '../../../hooks/Authorize';
import { SelectWithSearch } from '../../../components/Select';

const NewGoal = () => {
    const { goalId, clientId } = useParams();

    const { showSuccess } = useMessage();
    const navigate = useNavigate();
    const [goals, setGoals] = useState({});
    const errorHandler = useErrorHandler();
    const [categories, setCategories] = useState([]);
    const { start, end, loaderState } = useLoader();
    const user = useUser();

    const handlers = useForm(
        useMemo(
            () => ({
                client: { value: '', final: v => clientId },
                goal: { required: true },
                set_date: {
                    required: true,
                    value: new Date().toISOString().slice(0, 10),
                },
                target_date: {
                    required: true,
                    value: new Date().toISOString().slice(0, 10),
                },
                status: { required: true, value: 'In Progress' },
                remark: {},
                activity: { value: [] },
                category: { required: true },
            }),
            [clientId]
        ),
        { Input: TextField }
    );

    const setValues = handlers.setValues;

    const getClientGoal = useCallback(async () => {
        start();
        try {
            const response = await server.get(`/client-goals/${goalId}`);
            const {
                client,
                goal,
                set_date,
                target_date,
                status,
                remark,
                activity,
                category,
            } = response.data;

            setValues({
                client,
                goal,
                set_date,
                target_date,
                status,
                remark,
                activity,
                category,
            });
        } catch (e) {
            errorHandler(e);
        } finally {
            end();
        }
    }, [goalId, start, end, setValues, errorHandler]);

    const customChangeHandler = e => {
        const { name, value } = e.target;
        handlers.setValues({ [name]: value });
    };

    const onSubmit = response => {
        if (response.data) {
            navigate(-1);
            showSuccess('Goal saved successfully!');
        }
    };

    const getGoals = useCallback(
        async search => {
            try {
                const params = {
                    department: user.department,
                    category: handlers.values.category,
                };

                if (search) params.search = search;

                const response = await server.get(`/goals/`, { params });

                setGoals({});
                const goals = response.data.results;

                const format = {};

                goals.forEach(goal => (format[goal.id] = goal.goal));

                setGoals(format);
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler, user.department, handlers.values.category]
    );

    const getCategories = useCallback(async () => {
        try {
            const params = {
                department: user.department,
            };
            const response = await server.get(`/goal-cat/`, { params });
            const categories = response.data;

            setCategories(categories.results);
        } catch (e) {
            errorHandler(e);
        }
    }, [errorHandler, user.department]);

    useEffect(() => {
        user.department && getCategories();
    }, [getCategories, user.department]);

    useEffect(() => {
        if (goalId) getClientGoal();
    }, [goalId, getClientGoal]);

    useEffect(() => {
        user.department && getGoals();
    }, [getGoals, user.department]);

    const isDisabled = Boolean(goalId);

    return (
        <Box
            bgcolor='background.paper'
            height='calc(100vh - 76px)'
            overflow='auto'
            display='flex'
            flexDirection='column'
            p={0}>
            <Typography variant='h5' fontWeight={600} color='primary' p={2}>
                {goalId ? 'Edit Goal' : 'New Goal'}
            </Typography>
            <Divider variant='fullWidth' />
            {loaderState ? (
                <Loading message='Please wait, while your credentials are loading...' />
            ) : (
                <Form
                    handlers={handlers}
                    onSubmit={onSubmit}
                    action={
                        goalId ? `/client-goals/${goalId}` : '/client-goals/'
                    }
                    method={goalId ? 'patch' : 'post'}
                    axiosInstance={server}
                    onError={errorHandler}
                    style={{ flexGrow: 1, position: 'relative' }}>
                    <Box
                        p={2}
                        display='flex'
                        flexDirection='column'
                        mb={4}
                        overflow='auto'>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Category
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                lg={10}
                                display='flex'
                                spacing={2}>
                                <Select
                                    disabled={isDisabled}
                                    name='category'
                                    sx={{ width: 316 }}
                                    value={handlers.values.category}
                                    onChange={customChangeHandler}>
                                    {categories.map(category => (
                                        <MenuItem
                                            key={category.id}
                                            value={category.id}>
                                            {category.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Goal
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                lg={10}
                                display='flex'
                                spacing={2}>
                                <SelectWithSearch
                                    disabled={isDisabled}
                                    displayEmpty
                                    name='goal'
                                    onChange={customChangeHandler}
                                    value={handlers.values.goal}
                                    renderValue={v => {
                                        if (!handlers.values.goal) return '';
                                        return goals[v];
                                    }}
                                    sx={{ width: 316 }}
                                    SearchProps={{
                                        onChange: e => getGoals(e.target.value),
                                    }}>
                                    {Object.keys(goals).map((goal, i) => (
                                        <MenuItem
                                            value={goal}
                                            key={i}
                                            selected={false}
                                            sx={{ px: 1.2 }}>
                                            {goals[goal]}
                                        </MenuItem>
                                    ))}
                                </SelectWithSearch>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Set Date
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10}>
                                <Input
                                    name='set_date'
                                    variant='outlined'
                                    type='date'
                                    sx={{ width: 316 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Target Date
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10}>
                                <Input
                                    name='target_date'
                                    variant='outlined'
                                    type='date'
                                    sx={{ width: 316 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Status
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10}>
                                <Select
                                    value={handlers.values.status}
                                    fullWidth
                                    name='status'
                                    onChange={customChangeHandler}
                                    sx={{ width: 316 }}>
                                    <MenuItem value='In Progress'>
                                        In Progress
                                    </MenuItem>
                                    <MenuItem value='Achieved'>
                                        Achieved
                                    </MenuItem>
                                </Select>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Remark
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                lg={10}
                                display='flex'
                                spacing={2}>
                                <Input
                                    name='remark'
                                    variant='outlined'
                                    size='small'
                                    sx={{ width: 316 }}
                                />
                            </Grid>
                        </Grid>
                        <Divider sx={{ my: 5 }} />
                    </Box>
                    <Stack
                        direction='row'
                        p={2}
                        spacing={2}
                        sx={{
                            boxShadow: '0 -4px 5px -3px rgba(0,0,0,.1);',
                            backgroundColor: 'background.paper',
                            zIndex: 1100,
                            position: 'fixed',
                            bottom: 0,
                            width: {
                                xs: '100%',
                                xm: `calc(100% - 280px)`,
                            },
                        }}>
                        <Submit>
                            {loader => (
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    type='submit'
                                    disabled={Boolean(loader)}
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    endIcon={loader}>
                                    Save
                                </Button>
                            )}
                        </Submit>
                        <Button
                            onClick={() => navigate(-1)}
                            sx={{
                                bgcolor: 'background.default',
                                border: '1px solid',
                                borderColor: 'divider',
                            }}>
                            Cancel
                        </Button>
                    </Stack>
                </Form>
            )}
        </Box>
    );
};

export default NewGoal;
