import GridViewIcon from '@mui/icons-material/GridView';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import FlagCircleOutlinedIcon from '@mui/icons-material/FlagCircleOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';

const MenuItems = [
    {
        name: 'Dashboard',
        icon: <GridViewIcon fontSize='small' />,
        to: '/',
    },
    {
        name: 'Clients',
        icon: <PersonOutlinedIcon fontSize='small' />,
        to: '/clients',
    },
    {
        name: 'Assessments',
        icon: <AssessmentOutlinedIcon fontSize='small' />,
        to: '/assessments',
    },
    {
        name: 'Client Goals',
        icon: <FlagCircleOutlinedIcon fontSize='small' />,
        to: '/client-goals',
    },
    {
        name: 'Session Report',
        icon: <PersonOutlinedIcon fontSize='small' />,
        to: '/session',
    },
];

const AdminMenuItems = [
    {
        name: 'Users',
        icon: <PersonOutlinedIcon fontSize='small' />,
        to: '/users',
    },
    {
        name: 'Master Data',
        icon: <InsightsOutlinedIcon fontSize='small' />,
        to: [
            {
                name: 'Goal',
                icon: <FlagCircleOutlinedIcon fontSize='small' />,
                to: '/master/goal',
            },
            {
                name: `Goal's Category`,
                icon: <CategoryOutlinedIcon fontSize='small' />,
                to: '/master/category',
            },
            {
                name: 'Activity',
                icon: <DirectionsRunOutlinedIcon fontSize='small' />,
                to: '/master/activity',
            },
            {
                name: `Department`,
                icon: <LocalActivityOutlinedIcon fontSize='small' />,
                to: '/master/department',
            },
            {
                name: `Forms`,
                icon: <LocalActivityOutlinedIcon fontSize='small' />,
                to: '/master/forms',
            },
        ],
    },
];

export { MenuItems, AdminMenuItems };
