function env(name) {
    const nodeENV = process.env.NODE_ENV.toUpperCase();

    return (
        process.env[`REACT_APP_${nodeENV}_${name}`] ||
        process.env[`REACT_APP_${name}`]
    );
}

const getSession = name => sessionStorage[name];

const setSession = (name, value) => (sessionStorage[name] = value);

const deleteSession = name => sessionStorage.removeItem(name);

const isDefined = v => typeof v !== 'undefined';

const calcAge = DOB => {
    const dob = new Date(DOB);
    const today = new Date();

    let yearsDiff = today.getFullYear() - dob.getFullYear();
    let monthsDiff = today.getMonth() - dob.getMonth();

    if (
        monthsDiff < 0 ||
        (monthsDiff === 0 && today.getDate() < dob.getDate())
    ) {
        yearsDiff--;
    }

    // Calculate the remaining months
    const remainingMonths = (today.getMonth() + 12 - dob.getMonth()) % 12;

    return { years: yearsDiff, months: remainingMonths };
};

function isEmpty(obj) {
    try {
        return !Object.keys(obj).length;
    } catch (e) {
        return false;
    }
}

const falsy = v => !v;
const arrayEmpty = v => !v.length;

const formatDate = date => {
    if (!date) return undefined;

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

export {
    env,
    getSession,
    setSession,
    deleteSession,
    isDefined,
    calcAge,
    isEmpty,
    falsy,
    arrayEmpty,
    formatDate,
};
