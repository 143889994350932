import {
    MenuItem,
    Select,
    TextField,
    Stack,
    Divider,
    Checkbox,
    Box,
    Button,
    Grid,
    Typography,
    FormHelperText,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Submit, useForm } from '../../../hooks/useForm';
import { Input } from '../../../hooks/useForm/inputs/index';
import Loading from '../../../components/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import { Tab, Tabs } from '../../../styles/tabs';
import useLoader from '../../../hooks/useLoader';
import useErrorHandler from '../../../hooks/useErrorHandler';
import { server } from '../../../utils/axios';
import { useMessage } from '../../../components/Header';
import validators from '../../../utils/validators';

function TabPanel(props) {
    const { children, value, index } = props;

    return (
        <div role='tabpanel' hidden={value !== index} style={{ height: '90%' }}>
            {value === index && children}
        </div>
    );
}

const therapyDetails = [
    'therapy_type',
    'departments',
    'chief_complaints',
    'diagnosis',
    'remarks',
];
const address = ['address', 'branch'];

const NewClient = () => {
    const { clientId } = useParams();
    const [tabSelected, setTabSelected] = useState(0);
    const [checked, setChecked] = useState(false);
    const [departments, setDepartments] = useState([]);
    const { start, end, loaderState } = useLoader();
    const handleCheckChange = (event, newValue) => {
        setTabSelected(newValue);
    };
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const { showSuccess } = useMessage();

    const handlers = useForm(
        useMemo(
            () => ({
                name: { required: true },
                gender: {},
                dob: { value: '', final: v => (v ? v : null) },
                mother_tongue: {},
                father_name: {},
                mother_name: {},
                email: { validator: validators.email },
                phone: { validator: validators.phone, required: true },
                address: {},
                branch: {},
                therapy_type: {},
                departments: { value: [] },
                discontinuous: { value: '', final: v => checked },
                alternate_phone: '',
                slot_time_from: { value: '', final: v => (v ? v : null) },
                slot_time_to: { value: '', final: v => (v ? v : null) },
                chief_complaints: {},
                diagnosis: {},
                remarks: {},
                medical_history: '',
                weight: '',
                height: '',
            }),
            [checked]
        ),
        { Input: TextField }
    );

    const setValues = handlers.setValues;
    const errors = handlers.errors;

    const getClient = useCallback(async () => {
        start();
        try {
            const response = await server.get(`/clients/${clientId}`, {
                params: {
                    expand: 'departments',
                },
            });
            const {
                name,
                gender,
                dob,
                email,
                phone,
                alternate_phone,
                mother_tongue,
                father_name,
                mother_name,
                address,
                branch,
                discontinuous,
                slot_time_from,
                slot_time_to,
                therapy_type,
                chief_complaints,
                departments,
                diagnosis,
                remarks,
                medical_history,
                height,
                weight,
            } = response.data;

            setValues({
                name,
                gender,
                dob,
                mother_tongue,
                father_name,
                email,
                phone,
                alternate_phone,
                mother_name,
                address,
                branch,
                slot_time_from,
                slot_time_to,
                departments: departments.map(department => department.id),
                therapy_type,
                chief_complaints,
                diagnosis,
                remarks,
                medical_history,
                height,
                weight,
            });
            setChecked(discontinuous);
        } catch (e) {
            errorHandler(e);
        } finally {
            end();
        }
    }, [clientId, start, end, setValues, errorHandler]);

    const customChangeHandler = e => {
        const { name, value } = e.target;
        if (name === 'departments') {
            return setValues({
                [name]: typeof value === 'string' ? value.split(',') : value,
            });
        }
        setValues({ [name]: value });
    };

    const onSubmit = response => {
        if (response.data) {
            navigate(-1);
            showSuccess('Client saved successfully!');
        }
    };

    const getDepartments = useCallback(async () => {
        try {
            const response = await server.get(`/departments/`);
            const departments = response.data;

            setDepartments(departments.results);
        } catch (e) {
            errorHandler(e);
        }
    }, [errorHandler]);

    useEffect(() => {
        if (clientId) {
            getClient();
        }
    }, [clientId, getClient]);

    useEffect(() => {
        getDepartments();
    }, [getDepartments]);

    useEffect(() => {
        const detailTabError = therapyDetails.some(field => errors[field]);
        if (detailTabError) setTabSelected(1);

        const addressTabError = address.some(field => errors[field]);
        if (addressTabError) setTabSelected(0);
    }, [errors]);

    return (
        <Box
            bgcolor='background.paper'
            height='calc(100vh - 76px)'
            overflow='auto'
            display='flex'
            flexDirection='column'
            p={0}>
            <Typography variant='h5' fontWeight={600} color='primary' p={2}>
                {clientId ? 'Edit Client' : 'New Client'}
            </Typography>
            <Divider variant='fullWidth' />
            {loaderState ? (
                <Loading message='Please wait, while your credentials are loading...' />
            ) : (
                <Form
                    handlers={handlers}
                    onSubmit={onSubmit}
                    action={clientId ? `/clients/${clientId}` : '/clients/'}
                    axiosInstance={server}
                    method={clientId ? 'patch' : 'post'}
                    onError={errorHandler}
                    style={{ flexGrow: 1, position: 'relative' }}>
                    <Box
                        p={2}
                        display='flex'
                        flexDirection='column'
                        mb={4}
                        overflow='auto'>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Client Name
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                lg={10}
                                display='flex'
                                spacing={2}>
                                <Input
                                    name='name'
                                    variant='outlined'
                                    size='small'
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '90%',
                                            lg: '316px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Date of Birth
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                lg={10}
                                display='flex'
                                spacing={2}>
                                <Input
                                    name='dob'
                                    variant='outlined'
                                    size='small'
                                    type='date'
                                    sx={{ width: 150, mr: 2 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Client Height (in cm)
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                lg={10}
                                display='flex'
                                spacing={2}>
                                <Input
                                    name='height'
                                    variant='outlined'
                                    size='small'
                                    type='number'
                                    sx={{ width: 150, mr: 2 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Client Weight (in kg)
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                lg={10}
                                display='flex'
                                spacing={2}>
                                <Input
                                    name='weight'
                                    variant='outlined'
                                    size='small'
                                    type='number'
                                    sx={{ width: 150, mr: 2 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Gender
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10}>
                                <Select
                                    size='small'
                                    name='gender'
                                    sx={{ width: 316 }}
                                    error={errors.gender}
                                    value={handlers.values.gender}
                                    onChange={customChangeHandler}>
                                    <MenuItem value='male'>Male</MenuItem>
                                    <MenuItem value='female'>Female</MenuItem>
                                    <MenuItem value='other'>Other</MenuItem>
                                </Select>
                                <FormHelperText
                                    error={errors.gender}
                                    sx={{ ml: 2 }}>
                                    {errors.gender}
                                </FormHelperText>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Mother Tongue
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10}>
                                <Input
                                    name='mother_tongue'
                                    variant='outlined'
                                    size='small'
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '90%',
                                            lg: '316px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Father name
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10}>
                                <Input
                                    name='father_name'
                                    variant='outlined'
                                    size='small'
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '90%',
                                            lg: '316px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Mother name
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10}>
                                <Input
                                    name='mother_name'
                                    variant='outlined'
                                    size='small'
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '90%',
                                            lg: '316px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Client Email
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={10}>
                                <Input
                                    name='email'
                                    variant='outlined'
                                    size='small'
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '90%',
                                            lg: '316px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Client Phone
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                lg={10}
                                display='flex'
                                spacing={2}>
                                <Input
                                    name='phone'
                                    variant='outlined'
                                    placeholder='Primary'
                                    size='small'
                                    type='number'
                                    sx={{ mr: 2, width: 150 }}
                                />
                                <Input
                                    name='alternate_phone'
                                    variant='outlined'
                                    placeholder='Alternate'
                                    size='small'
                                    type='number'
                                    sx={{ width: 150 }}
                                />
                            </Grid>
                        </Grid>

                        <Tabs
                            value={tabSelected}
                            onChange={handleCheckChange}
                            sx={{ mt: 4 }}>
                            <Tab label='Address' />
                            <Tab label='Therapy Details' />
                        </Tabs>
                        <Box flexGrow={1} py={2} mb={3}>
                            <TabPanel value={tabSelected} index={0}>
                                <Grid
                                    container
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems='center'
                                    mb={2}>
                                    <Grid item xs={12} lg>
                                        <Typography
                                            variant='body2'
                                            fontWeight={600}>
                                            Adddress
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} lg={10}>
                                        <Input
                                            name='address'
                                            variant='outlined'
                                            size='small'
                                            multiline
                                            rows={4}
                                            sx={{
                                                width: {
                                                    xs: '100%',
                                                    sm: '90%',
                                                    lg: '316px',
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems='center'
                                    mb={2}>
                                    <Grid item xs={12} lg>
                                        <Typography
                                            variant='body2'
                                            fontWeight={600}>
                                            Branch
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} lg={10}>
                                        <Select
                                            name='branch'
                                            variant='outlined'
                                            size='small'
                                            sx={{
                                                width: {
                                                    xs: '100%',
                                                    sm: '90%',
                                                    lg: '316px',
                                                },
                                            }}
                                            error={errors.branch}
                                            value={handlers.values.branch}
                                            onChange={customChangeHandler}>
                                            <MenuItem value='Miyapur'>
                                                Miyapur
                                            </MenuItem>
                                            <MenuItem value='Banjara Hills'>
                                                Banjara Hills
                                            </MenuItem>
                                        </Select>
                                        <FormHelperText
                                            error={errors.branch}
                                            sx={{ ml: 2 }}>
                                            {errors.branch}
                                        </FormHelperText>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={tabSelected} index={1}>
                                <Grid
                                    container
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems='center'
                                    mb={2}>
                                    <Grid item xs={12} lg>
                                        <Typography
                                            variant='body2'
                                            fontWeight={600}>
                                            Therapy Type
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} lg={10}>
                                        <Select
                                            name='therapy_type'
                                            size='small'
                                            sx={{ width: 316 }}
                                            error={errors.therapy_type}
                                            value={handlers.values.therapy_type}
                                            onChange={customChangeHandler}>
                                            <MenuItem value='Integrated'>
                                                Integrated
                                            </MenuItem>
                                            <MenuItem value='Individual'>
                                                Individual
                                            </MenuItem>
                                        </Select>
                                        <FormHelperText
                                            error={errors.therapy_type}
                                            sx={{ ml: 2 }}>
                                            {errors.therapy_type}
                                        </FormHelperText>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems='center'
                                    mb={2}>
                                    <Grid item xs={12} lg>
                                        <Typography
                                            variant='body2'
                                            fontWeight={600}>
                                            Departments
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} lg={10}>
                                        <Select
                                            name='departments'
                                            size='small'
                                            sx={{ width: 316 }}
                                            error={errors.departments}
                                            value={handlers.values.departments}
                                            multiple
                                            onChange={customChangeHandler}>
                                            {departments.map(department => (
                                                <MenuItem
                                                    value={department.id}
                                                    key={department.id}>
                                                    {department.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText
                                            error={errors.departments}
                                            sx={{ ml: 2 }}>
                                            {errors.departments}
                                        </FormHelperText>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems='center'
                                    mb={2}>
                                    <Grid item xs={12} lg>
                                        <Typography
                                            variant='body2'
                                            fontWeight={600}>
                                            Slot time
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        lg={10}
                                        display='flex'
                                        spacing={2}>
                                        <Input
                                            name='slot_time_from'
                                            variant='outlined'
                                            type='time'
                                            placeholder='From'
                                            size='small'
                                            sx={{ mr: 2, width: 150 }}
                                        />
                                        <Input
                                            name='slot_time_to'
                                            variant='outlined'
                                            placeholder='To'
                                            type='time'
                                            size='small'
                                            sx={{ width: 150 }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems='center'
                                    mb={2}>
                                    <Grid item xs={12} lg>
                                        <Typography
                                            variant='body2'
                                            fontWeight={600}>
                                            Discontinued
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} lg={10}>
                                        <Checkbox
                                            checked={checked}
                                            onChange={e =>
                                                setChecked(e.target.checked)
                                            }
                                        />
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems='center'
                                    mb={2}>
                                    <Grid item xs={12} lg>
                                        <Typography
                                            variant='body2'
                                            fontWeight={600}>
                                            Chief Complaints
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} lg={10}>
                                        <Input
                                            name='chief_complaints'
                                            multiline
                                            rows={4}
                                            variant='outlined'
                                            size='small'
                                            sx={{
                                                width: {
                                                    xs: '100%',
                                                    sm: '90%',
                                                    lg: '316px',
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems='center'
                                    mb={2}>
                                    <Grid item xs={12} lg>
                                        <Typography
                                            variant='body2'
                                            fontWeight={600}>
                                            Diagnosis
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} lg={10}>
                                        <Input
                                            name='diagnosis'
                                            variant='outlined'
                                            multiline
                                            rows={4}
                                            size='small'
                                            sx={{
                                                width: {
                                                    xs: '100%',
                                                    sm: '90%',
                                                    lg: '316px',
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems='center'
                                    mb={2}>
                                    <Grid item xs={12} lg>
                                        <Typography
                                            variant='body2'
                                            fontWeight={600}>
                                            Remarks
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} lg={10}>
                                        <Input
                                            name='remarks'
                                            variant='outlined'
                                            size='small'
                                            multiline
                                            rows={4}
                                            sx={{
                                                width: {
                                                    xs: '100%',
                                                    sm: '90%',
                                                    lg: '316px',
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems='center'
                                    mb={2}>
                                    <Grid item xs={12} lg>
                                        <Typography
                                            variant='body2'
                                            fontWeight={600}>
                                            Medical History
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} lg={10}>
                                        <Input
                                            name='medical_history'
                                            variant='outlined'
                                            size='small'
                                            multiline
                                            rows={4}
                                            sx={{
                                                width: {
                                                    xs: '100%',
                                                    sm: '90%',
                                                    lg: '316px',
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </Box>
                    </Box>
                    <Stack
                        direction='row'
                        p={2}
                        spacing={2}
                        sx={{
                            boxShadow: '0 -4px 5px -3px rgba(0,0,0,.1);',
                            backgroundColor: 'background.paper',
                            zIndex: 1100,
                            position: 'fixed',
                            bottom: 0,
                            width: {
                                xs: '100%',
                                xm: `calc(100% - 280px)`,
                            },
                        }}>
                        <Submit>
                            {loader => (
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    type='submit'
                                    disabled={Boolean(loader)}
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    endIcon={loader}>
                                    Save
                                </Button>
                            )}
                        </Submit>
                        <Button
                            onClick={() => navigate(-1)}
                            sx={{
                                bgcolor: 'background.default',
                                border: '1px solid',
                                borderColor: 'divider',
                            }}>
                            Cancel
                        </Button>
                    </Stack>
                </Form>
            )}
        </Box>
    );
};

export default NewClient;
