import { TextField, Stack, Divider } from '@mui/material';
import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Submit, useForm } from '../../../hooks/useForm';
import { Input } from '../../../hooks/useForm/inputs/index';
import { useMessage } from '../../../components/Header';
import Loading from '../../../components/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import useLoader from '../../../hooks/useLoader';
import useErrorHandler from '../../../hooks/useErrorHandler';
import { server } from '../../../utils/axios';

const NewDepartment = () => {
    const { departmentId } = useParams();
    const { showSuccess } = useMessage();
    const { start, end, loaderState } = useLoader();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const [extraFields, setExtraFields] = useState([]);

    const handlers = useForm(
        useMemo(
            () => ({
                name: { required: true },
            }),
            []
        ),
        { Input: TextField }
    );

    const setValues = handlers.setValues;

    const getDepartment = useCallback(async () => {
        start();
        try {
            const response = await server.get(`/departments/${departmentId}`);

            const { name, assessment_fields } = response.data;
            setValues({
                name,
            });
            setExtraFields(assessment_fields);
        } catch (e) {
            errorHandler(e);
        } finally {
            end();
        }
    }, [departmentId, setValues, start, end, errorHandler]);

    const onSubmit = response => {
        if (response.data) {
            showSuccess('Department saved successfully');
            navigate(-1);
        }
    };

    useEffect(() => {
        if (departmentId) {
            getDepartment();
        }
    }, [departmentId, getDepartment]);

    return (
        <Box
            bgcolor='background.paper'
            height='calc(100vh - 76px)'
            overflow='auto'
            display='flex'
            flexDirection='column'
            p={0}>
            <Typography variant='h5' fontWeight={600} color='primary' p={2}>
                {departmentId ? `Edit Department` : `New Department`}
            </Typography>
            <Divider variant='fullWidth' />
            {loaderState ? (
                <Loading message='Please wait, while your credentials are loading...' />
            ) : (
                <Form
                    handlers={handlers}
                    onSubmit={onSubmit}
                    action={
                        departmentId
                            ? `/departments/${departmentId}`
                            : '/departments/'
                    }
                    method={departmentId ? 'patch' : 'post'}
                    axiosInstance={server}
                    onError={errorHandler}
                    final={values => ({
                        ...values,
                        assessment_fields: extraFields,
                    })}
                    style={{ flexGrow: 1, position: 'relative' }}>
                    <Box
                        p={2}
                        display='flex'
                        flexDirection='column'
                        mb={4}
                        overflow='auto'>
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems='center'
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant='body2' fontWeight={600}>
                                    Name
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                lg={10}
                                display='flex'
                                spacing={2}>
                                <Input
                                    name='name'
                                    variant='outlined'
                                    size='small'
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '90%',
                                            lg: '316px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Divider variant='fullWidth' sx={{ my: 2 }} />
                    </Box>
                    <Stack
                        direction='row'
                        p={2}
                        spacing={2}
                        sx={{
                            boxShadow: '0 -4px 5px -3px rgba(0,0,0,.1);',
                            backgroundColor: 'background.paper',
                            zIndex: 1100,
                            position: 'fixed',
                            bottom: 0,
                            width: {
                                xs: '100%',
                                xm: `calc(100% - 280px)`,
                            },
                        }}>
                        <Submit>
                            {loader => (
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    type='submit'
                                    disabled={Boolean(loader)}
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    endIcon={loader}>
                                    Save
                                </Button>
                            )}
                        </Submit>
                        <Button
                            onClick={() => navigate(-1)}
                            sx={{
                                bgcolor: 'background.default',
                                border: '1px solid',
                                borderColor: 'divider',
                            }}>
                            Cancel
                        </Button>
                    </Stack>
                </Form>
            )}
        </Box>
    );
};

export default NewDepartment;
