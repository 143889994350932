import React, { useCallback, useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, Grid } from '@mui/material';
import { PieChart as MuiPieChart } from '@mui/x-charts';
import { server } from '../../utils/axios';
import useErrorHandler from '../../hooks/useErrorHandler';

const PieChart = () => {
    const errorHandler = useErrorHandler();
    const [data, setData] = useState([]);

    const fetchPendingAssessments = useCallback(async () => {
        try {
            const response = await server.get('/pending-assesment/');
            const pendingAssessment = response.data.pending_assesment;

            const formattedData = pendingAssessment.map(assessment => ({
                name: assessment.department__name,
                value: assessment.count,
            }));

            setData(formattedData);
        } catch (error) {
            errorHandler(error);
        }
    }, [errorHandler]);

    useEffect(() => {
        fetchPendingAssessments();
    }, [fetchPendingAssessments]);

    const colors = ['red', 'green', 'blue'];

    return (
        <Card elevation={0} sx={{ height: '100%' }}>
            <CardContent>
                <Typography variant='h6'>Pending Assessments</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <MuiPieChart
                        series={[
                            {
                                data: data.map(item => ({
                                    name: item.name,
                                    value: item.value,
                                })),
                                innerRadius: 110,
                                outerRadius: 120,
                                cx: 150,
                            },
                        ]}
                        height={340}
                        colors={colors}
                    />
                </Box>
                <Box mt={2}>
                    <Grid container spacing={1}>
                        {data.map((item, index) => (
                            <Grid item xs={4} key={item.name}>
                                <Box
                                    display='flex'
                                    alignItems='center'
                                    justifyContent='center'>
                                    <Box
                                        sx={{
                                            width: 16,
                                            height: 16,
                                            backgroundColor: colors[index],
                                            marginRight: 1,
                                        }}
                                    />
                                    <Typography variant='body2'>
                                        {item.name} ({item.value})
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    );
};

export default PieChart;
